/* eslint-disable no-param-reassign */
import { schema } from 'normalizr'
import { getRawId, isGid, buildId, buildIdFromAny } from 'util/globalId'
import {
  snakecaseKeys,
  camelizeObjectKeys,
  snakecaseKeysNested,
} from 'util/objects'
import { hash, convertGidToScatterId } from 'util/scatterSwap'
import { camelize, capitalize } from 'util/strings'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import { isDefined } from 'util/nullOrUndefinedChecks'
import {
  formatDateForMessage,
  isTicketActionChangeBodyEmpty,
} from 'ducks/tickets/utils/message'
import { isOpen } from 'ducks/tickets/utils/state'
import {
  queryIdToQuery,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { SORT_ORDERS_FOR_FOLDER } from 'util/folders/SORT_ORDERS_FOR_FOLDER'
import { RULE_STATE } from 'ducks/rules/constants'

const convertToV1AndStoreRawIdField = (field, entity) => {
  const rawFieldName = `raw${capitalize(field)}`

  if (!entity[rawFieldName]) entity[rawFieldName] = entity[field]
  const scatterId = hash(entity[rawFieldName])
  if (entity[field] !== scatterId) entity[field] = scatterId
}

const convertToV2AndStoreRawIdField = (field, gidType, entity) => {
  const rawFieldName = `raw${capitalize(field)}`

  if (!entity[rawFieldName]) entity[rawFieldName] = entity[field]
  const gid = buildId(gidType, hash(entity[rawFieldName]))
  if (entity[field] !== gid) entity[field] = gid
}

const idToEntity = (idField, entityType, entity) => {
  entity[entityType] = { id: entity[idField] }
}

const processStrategyV0ToV1 = entity => {
  const updatedEntity = camelizeObjectKeys(entity)
  convertToV1AndStoreRawIdField('id', updatedEntity)
  return updatedEntity
}

const processStrategyV0ToV2 = gidType => entity => {
  const updatedEntity = camelizeObjectKeys(entity)
  convertToV2AndStoreRawIdField('id', gidType, updatedEntity)
  return updatedEntity
}

const processStrategyRemoveNodesLevel = entity => {
  const result = {}

  if (typeof entity !== 'object') return entity
  const entries = Object.entries(entity)

  entries.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      result[key] = value.map(item => processStrategyRemoveNodesLevel(item))
    } else if (typeof value === 'object' && value !== null) {
      if (value.nodes) {
        result[key] = value.nodes.map(item =>
          processStrategyRemoveNodesLevel(item)
        )
      } else if (value.edges) {
        result[key] = value.edges.map(item =>
          processStrategyRemoveNodesLevel(item)
        )
      } else if (key === 'node') {
        Object.assign(result, processStrategyRemoveNodesLevel(value))
      } else {
        result[key] = processStrategyRemoveNodesLevel(value)
      }
    } else {
      result[key] = value
    }
  })
  return result
}

const processStrategyMessage = entity => {
  const updatedEntity = { ...entity }
  const { parts = [] } = entity
  updatedEntity.isBodyEmpty = isTicketActionChangeBodyEmpty(
    updatedEntity.body || updatedEntity.bodyPlainText
  )
  if (!updatedEntity.isBodyEmpty && parts.length === 0) {
    updatedEntity.parts = [
      {
        type: 'TEXT',
        content: updatedEntity.body || updatedEntity.bodyPlainText,
      },
    ]
  }
  // CONVERTME
  updatedEntity.hasRawEmail = false

  return updatedEntity
}

const processStrategyAgent = entity => {
  const updatedEntity = { ...entity }
  updatedEntity.id = getRawId(entity.id)
  return updatedEntity
}

const processStrategyTeam = entity => {
  const updatedEntity = { ...entity }
  updatedEntity.id = getRawId(entity.id)
  if (entity.agent_ids !== undefined) {
    updatedEntity.agents = entity.agent_ids.map(agentId => ({
      id: agentId,
    }))
    delete updatedEntity.agent_ids
  }
  return updatedEntity
}

const defaultFolderSortOrderKey = folder => {
  let sortOrder
  SORT_ORDERS_FOR_FOLDER.forEach((func, key) => {
    if (func(folder)) {
      sortOrder = key
      // eslint-disable-next-line no-useless-return
      return
    }
  })

  return sortOrder
}

const processStrategyFolder = entity => {
  const updatedEntity = { ...entity }

  if (entity.state !== undefined) {
    updatedEntity.visible = entity.state === 'ACTIVE'
  }
  updatedEntity.queryId = `folder:${entity.id}`
  if (entity.conditions || updatedEntity.defaultSortOrderKey) {
    updatedEntity.defaultSortOrderKey = defaultFolderSortOrderKey(entity)
  }

  return updatedEntity
}

const processStrategyPinnedSearch = entity => {
  const updatedEntity = { ...entity }
  const query = queryIdToQuery(updatedEntity.query)
  if (!query.type) {
    updatedEntity.query = queryStringToQueryId({
      ...query,
      type: MAILBOX_CHANNEL_TYPE,
    })
  }
  return updatedEntity
}

const processStrategyConversation = entity => {
  const updatedEntity = { ...entity }
  updatedEntity.isOpen = isOpen(entity)
  return updatedEntity
}

const processStrategyFormattedCreatedAt = entity => {
  const updatedEntity = { ...entity }
  updatedEntity.formattedCreatedAt = formatDateForMessage(
    updatedEntity.createdAt
  )

  return updatedEntity
}

const processStrategyAddGid = gidType => entity => {
  if (entity.gid) return entity
  const updatedEntity = { ...entity }
  updatedEntity.id = getRawId(entity.id)
  updatedEntity.gid = buildId(gidType, updatedEntity.id)
  return updatedEntity
}
const processStrategyMailboxId = entity => {
  const updatedEntity = { ...entity }
  updatedEntity.mailboxId = getRawId(entity.channel.id)
  return updatedEntity
}

const processStrategyGidToId = field => entity => {
  const updatedEntity = { ...entity }
  updatedEntity[field] = getRawId(updatedEntity[field])
  return updatedEntity
}

export const processStrategyCustomFieldValuesToProperties = entity => {
  const updatedEntity = { ...entity }
  if (updatedEntity.customFieldValues === null) {
    delete updatedEntity.customFieldValues
  }

  if (updatedEntity.customFieldValues) {
    updatedEntity.customFieldValues = updatedEntity.customFieldValues.reduce(
      (result, fieldValue) => {
        const {
          key: valueKey, // This is actually the custom field key but sometimes it is on the value
          customField: { isArray, key = valueKey } = {},
          mode,
          ...rest
        } = fieldValue
        const existingValue = result[key]
        if (isArray) {
          rest.value = [...(existingValue?.value || []), rest.value]
        }
        result[key] = { key, ...rest }
        return result
      },
      {}
    )
  }

  return updatedEntity
}

export const processStrategyChannel = entity => {
  const updatedEntity = { ...entity }

  if (isChatChannelType(entity.channelType)) {
    updatedEntity.color = entity.channelColor
    updatedEntity.address = entity.name
    updatedEntity.state = entity?.publishedSettings?.enabled
      ? 'active'
      : entity.status
    delete updatedEntity.channelColor
    return snakecaseKeys(updatedEntity)
  }

  updatedEntity.id = getRawId(entity.id)
  updatedEntity.gid = entity.id

  if ('restriction' in updatedEntity) {
    updatedEntity.restriction_type = entity?.restriction?.type
    updatedEntity.group_ids = entity.restriction.teams.map(item => item.id)
    updatedEntity.user_ids = entity.restriction.agents.map(item => item.id)
  }

  if (entity.permittedAgents !== undefined) {
    updatedEntity.hasAccess = entity.permittedAgents.length > 0
  }

  updatedEntity.created_at = entity.createdAt

  updatedEntity.channel_type = entity.type

  updatedEntity.forward_email_address = entity.forwardEmailAddress

  if ('email_integration_error' in updatedEntity) {
    updatedEntity.email_integration_error = {
      error_type: entity?.errors?.type,
      error_message: entity?.errors?.message,
    }
  }

  if ('useAgentName' in updatedEntity) {
    updatedEntity.use_agent_name = entity.useAgentName
    updatedEntity.auto_bcc_address = entity.autoBccAddress
  }

  return updatedEntity
}

export const processStrategyRule = entity => {
  const updatedEntity = { ...entity }
  updatedEntity.active = entity.state === RULE_STATE.ACTIVE
  return updatedEntity
}

export const processStrategyAttachment = entity => {
  const updatedEntity = { ...entity }

  if (entity.gid !== undefined) {
    updatedEntity.id = updatedEntity.gid
    delete updatedEntity.gid
  }
  if (entity.creator_gid !== undefined) {
    updatedEntity.creator = getRawId(entity.creator_gid)
    delete updatedEntity.creator_gid
  }
  // These backward compatibile mappings should be removed after
  // canned replies are migrated to use GQLV2 aswell
  updatedEntity.fileName =
    entity.fileName || entity.attachment_file_name || entity.file_name
  updatedEntity.fileSize = parseInt(
    entity.fileSize || entity.attachment_file_size || entity.file_size,
    10
  )
  updatedEntity.fileType = entity.fileType || entity.attachment_content_type
  updatedEntity.createdAt = entity.createdAt || entity.created_at
  updatedEntity.url = entity.url || entity.token_url
  updatedEntity.downloadUrl = entity.downloadUrl || entity.token_download_url
  updatedEntity.s3Key = entity.s3Key || entity.s3_key
  updatedEntity.ticketId = buildIdFromAny('Conversation', entity.ticketId)
  updatedEntity.commentId = buildIdFromAny('Message', entity.commentId)

  return updatedEntity
}

const joinStrategies = strategyFunctions => {
  return entity => {
    const copiedEntity = { ...entity }
    return strategyFunctions.reduce((modifiedEntity, strategy) => {
      return strategy(modifiedEntity, entity)
    }, copiedEntity)
  }
}

const idAttributeV0ToV1 = entity => {
  return hash(entity.id)
}

const idAttributeV0ToV2 = gidType => entity => {
  if (isGid(entity.id)) {
    return entity.id
  }
  return buildId(gidType, hash(entity.id))
}

const idAttributeV1ToV2 = gidType => entity => {
  if (isGid(entity.id)) {
    return entity.id
  }
  return buildId(gidType, entity.id)
}

// support gid being returned from V0 settings api
// if exists, convert to id (scatterswap) which is used for overview page
// otherwise return normal id
const idAttributeV2ToV1 = entity => {
  return entity.gid ? getRawId(entity.gid) : entity.id
}

export const idAttributeGidToId = entity => {
  return getRawId(entity.id)
}

export const customFieldCategories = new schema.Entity('customFieldCategories')

export const customFields = new schema.Entity(
  'customFields',
  {
    category: customFieldCategories,
  },
  {
    processStrategy: joinStrategies([processStrategyRemoveNodesLevel]),
  }
)

export const companies = new schema.Entity(
  'companies',
  {},
  {
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyCustomFieldValuesToProperties,
    ]),
  }
)

export const contacts = new schema.Entity(
  'contacts',
  {},
  {
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyCustomFieldValuesToProperties,
    ]),
  }
)

export const defaultAgentNotificationPreferences = new schema.Entity(
  'defaultAgentNotificationPreferences',
  {},
  {
    idAttribute: 'key',
  }
)

export const agentNotificationPreferences = new schema.Entity(
  'agentNotificationPreferences',
  {},
  {
    idAttribute: 'key', // should be the id but key is easier for the notifications page + RHF
  }
)

export const agentPreference = new schema.Entity('agentPreferences')

export const agentPreferences = new schema.Entity(
  // HACK: agentPreferences conflicts with the agentsPreferences duck
  // which somehow cannot load the agent preeferences
  'userPreferences',
  {},
  {
    idAttribute: idAttributeV0ToV1,
    processStrategy: processStrategyV0ToV1,
  }
)

export const agent = new schema.Entity(
  'agent',
  {
    preferences: agentPreferences,
  },
  {
    idAttribute: idAttributeGidToId,
    processStrategy: processStrategyAgent,
  }
)

export const team = new schema.Entity(
  'team',
  {},
  {
    idAttribute: idAttributeGidToId,
    processStrategy: processStrategyTeam,
  }
)

export const cannedReplyCategory = new schema.Entity('cannedReplyCategory')

export const attachment = new schema.Entity(
  'attachment',
  {
    creator: agent,
  },
  {
    processStrategy: processStrategyAttachment,
  }
)

export const cannedReply = new schema.Entity(
  'cannedReply',
  {
    category: cannedReplyCategory,
    attachments: [attachment],
  },
  {
    idAttribute: idAttributeV2ToV1,
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      // START: V0 Settings API response
      if (entity.gid !== undefined) {
        updatedEntity.id = getRawId(entity.gid)
        delete updatedEntity.gid
      }

      if (entity.category_gid !== undefined) {
        updatedEntity.category_id = getRawId(entity.category_gid)
        delete updatedEntity.category_gid
      }

      if (entity.creator_gid !== undefined) {
        updatedEntity.creator_id = getRawId(entity.creator_gid)
        delete updatedEntity.creator_gid
      }

      if (entity.canned_reply_categories) {
        delete updatedEntity.canned_reply_categories
      }

      if (entity.title !== undefined) {
        // it's saved as name in the entity table
        updatedEntity.name = entity.title
        delete updatedEntity.title
      }
      // END: V0 Settings API response

      // using updatedEntity here because category_id might already been changed by v0 settings entity processing above
      if (updatedEntity.category_id !== undefined) {
        if (updatedEntity.category_id !== null) {
          updatedEntity.category = { id: updatedEntity.category_id }
        } else {
          updatedEntity.category = null
        }

        delete updatedEntity.category_id
      }

      return updatedEntity
    },
  }
)

export const starredCannedReply = new schema.Entity(
  'starredCannedReply',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      const gid = entity.id

      updatedEntity.gid = entity.id
      updatedEntity.id = getRawId(gid)

      return updatedEntity
    },
  }
)

export const cannedReplyVariables = new schema.Entity(
  'cannedReplyVariables',
  {},
  { idAttribute: 'key' }
)

export const channel = new schema.Entity(
  'channel',
  {},
  {
    idAttribute: idAttributeGidToId,
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyChannel,
    ]),
  }
)

export const folder = new schema.Entity(
  'folder',
  {
    agents: [agent],
    teams: [team],
    channels: [channel],
  },
  {
    idAttribute: idAttributeV1ToV2('Folder'),
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyFolder,
    ]),
  }
)

channel.define({
  folders: [folder],
  customFields: [customFields],
})

export const ruleReplyTemplate = new schema.Entity('ruleReplyTemplate', {
  attachments: [attachment],
})

export const ruleAction = new schema.Entity('ruleAction', {
  replyTemplate: ruleReplyTemplate,
})

export const ruleCondition = new schema.Entity(
  'ruleCondition',
  {},
  {
    processStrategy: joinStrategies([processStrategyRemoveNodesLevel]),
  }
)

export const ruleTrigger = new schema.Entity('ruleTrigger')

export const rule = new schema.Entity(
  'rule',
  {
    creator: agent,
    conditions: [ruleCondition],
    actions: [ruleAction],
    triggers: [ruleTrigger],
  },
  {
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyRule,
    ]),
  }
)

export const tag = new schema.Entity(
  'tag',
  {
    creator: agent,
  },
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      // Agents currently use scatterwap ids and we're returning gids.
      // we'll just convert that here for now
      if (entity.creator) {
        updatedEntity.creator.id = getRawId(entity.creator.id)
      }
      return updatedEntity
    },
  }
)

export const batchJob = new schema.Entity('batchJob')

export const integrationInstallStateProviderValue = new schema.Entity(
  'integrationInstallStateProviderValue'
)

export const integration = new schema.Entity('integration', {
  installStateValue: {
    provider: [integrationInstallStateProviderValue],
  },
})

export const widget = new schema.Entity(
  'widget',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      updatedEntity.enabled = entity.publishedSettings?.enabled
      updatedEntity.status = updatedEntity.enabled ? 'Visible' : 'Not visible'
      return snakecaseKeys(updatedEntity)
    },
  }
)

export const webhook = new schema.Entity(
  'webhook',
  {},
  {
    idAttribute: idAttributeV0ToV1,
    processStrategy: processStrategyV0ToV1,
  }
)

export const accessToken = new schema.Entity(
  'accessToken',
  {},
  {
    idAttribute: idAttributeV0ToV1,
    processStrategy: entity => {
      const updatedEntity = processStrategyV0ToV1(entity)
      convertToV1AndStoreRawIdField('resourceOwnerId', updatedEntity)
      return updatedEntity
    },
  }
)

export const spammer = new schema.Entity('spammer', {
  creator: agent,
})

export const emailMarketingIntegrationMailboxSetting = new schema.Entity(
  'emailMarketingIntegrationMailboxSetting',
  {
    channel,
  },
  {
    idAttribute: idAttributeV0ToV2('EmailMarketingIntegrationMailboxSetting'),
    processStrategy: entity => {
      const updatedEntity = processStrategyV0ToV2(
        'EmailMarketingIntegrationMailboxSetting'
      )(entity)
      convertToV1AndStoreRawIdField('mailboxId', updatedEntity)
      idToEntity('mailboxId', 'channel', updatedEntity)
      return updatedEntity
    },
  }
)

export const emailMarketingIntegration = new schema.Entity(
  'emailMarketingIntegration',
  {
    mailboxSettings: [emailMarketingIntegrationMailboxSetting],
  },
  {
    idAttribute: entity => entity.type.toUpperCase(),
    processStrategy: camelizeObjectKeys,
  }
)

export const dataExport = new schema.Entity(
  'dataExport',
  {},
  {
    processStrategy: entity => ({
      ...entity,
      requestedById: convertGidToScatterId(entity.requestedBy?.id),
    }),
  }
)

export const invoice = new schema.Entity('invoice')

export const facebookPage = new schema.Entity('facebookPage')

export const customerRatingSetting = new schema.Entity('customerRatingSetting')

export const processStrategyRuleTemplate = entity => {
  const updatedEntity = { ...entity }

  const {
    template: {
      matchType,
      state,
      conditions = [],
      actions = [],
      triggers = [],
      scheduleType,
    },
  } = updatedEntity

  // match the structure of rules entity used for create/edit drawer

  if (isDefined(matchType)) {
    updatedEntity.template.matchType = matchType.toLowerCase()
  }

  if (isDefined(state)) {
    updatedEntity.template.active = state === 'ACTIVE'
    delete updatedEntity.template.state
  }

  if (isDefined(conditions)) {
    updatedEntity.template.conditions = conditions.map(c => {
      if (isDefined(c.operator)) {
        c.operator = c.operator.toLowerCase()
      }

      if (isDefined(c.param)) {
        c.param = c.param.toLowerCase()
      }

      return c
    })
  }

  if (isDefined(actions)) {
    updatedEntity.template.actions = actions.map(a => {
      if (isDefined(a.type)) {
        a.type = a.type.toLowerCase()
      }

      return a
    })
  }

  if (isDefined(triggers)) {
    updatedEntity.template.triggers = triggers.map(t => {
      if (isDefined(t.type)) {
        t.type = t.type.toLowerCase()
      }

      return t
    })
  }

  if (isDefined(scheduleType)) {
    updatedEntity.template.scheduleType = scheduleType.toLowerCase()
  }

  updatedEntity.template = snakecaseKeysNested(updatedEntity.template)

  return updatedEntity
}

export const featureTemplate = new schema.Entity(
  'featureTemplate',
  {},
  {
    processStrategy: entity => {
      switch (entity?.type) {
        case 'rules':
          return processStrategyRuleTemplate(entity)
        default:
          return entity
      }
    },
  }
)
export const featureTemplateCategory = new schema.Entity(
  'featureTemplateCategory',
  {},
  {
    idAttribute: entity => entity,
  }
)

export const feature = new schema.Entity(
  'feature',
  {},
  {
    idAttribute: 'key',
    processStrategy: entity => ({
      ...entity,
      name: camelize(entity.name),
    }),
  }
)

export const usage = new schema.Entity(
  'usage',
  {},
  {
    idAttribute: 'key',
  }
)

export const pricing = new schema.Entity(
  'pricing',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      updatedEntity.usageFrom = updatedEntity.usageFrom.toLowerCase()
      updatedEntity.features = updatedEntity.features.map(f => ({
        ...f,
        name: camelize(f.name),
      }))
      return updatedEntity
    },
  }
)

export const plan = new schema.Entity('plan', {
  pricing,
})

export const account = new schema.Entity('account')

export const creditCard = new schema.Entity('creditCard')

export const coupon = new schema.Entity('coupon')

export const discount = new schema.Entity('discount', {
  coupon,
})

export const setupIntent = new schema.Entity('setupIntent')

export const author = new schema.Union(
  {
    Contact: contacts,
    Agent: agent,
  },
  '__typename'
)

export const conversation = new schema.Entity(
  'conversation',
  {
    assigned: {
      agent,
      team,
    },
    channel,
    contact: contacts,
    tags: [tag],
    followers: [agent],
  },
  {
    idAttribute: idAttributeGidToId,
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyAddGid('Conversation'),
      processStrategyMailboxId,
      processStrategyCustomFieldValuesToProperties,
      processStrategyConversation,
    ]),
  }
)

export const message = new schema.Entity(
  'message',
  {
    channel,
    attachments: [attachment],
    author,
    to: [author],
    cc: [author],
    bcc: [author],
  },
  {
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyMessage,
      processStrategyFormattedCreatedAt,
    ]),
  }
)

export const eventChange = new schema.Union(
  {
    // Not sure if this is the best way to do it. Right now splitting
    // the different types to differnt stores seems like needless complexity
    // on the client side data model
    EmailMessage: message,
    ForwardedMessage: message,
    Reply: message,
    Note: message,
    TwitterMessage: message,
    FacebookMessage: message,
    WidgetMessage: message,
  },
  '__typename'
)

export const customFieldValueSubject = new schema.Union(
  {
    Company: companies,
    Contact: contacts,
    EmailConversation: conversation,
    FacebookConversation: conversation,
    TwitterConversation: conversation,
    WidgetConversation: conversation,
  },
  '__typename'
)

export const actor = new schema.Union(
  {
    Contact: contacts,
    Agent: agent,
    Rule: rule,
    // Note im not sure if the UserIntegration and integration should
    // be mapped. Will check this once I have more of this mapped
    UserIntegration: integration,
  },
  '__typename'
)

export const conversationEvent = new schema.Entity(
  'conversationEvent',
  {
    change: eventChange,
    actor,
  },
  {
    processStrategy: joinStrategies([processStrategyFormattedCreatedAt]),
  }
)

export const conversationEventGroup = new schema.Entity(
  'conversationEventGroup',
  {
    summary: message,
    events: [conversationEvent],
    actor,
  },
  {
    processStrategy: joinStrategies([
      processStrategyRemoveNodesLevel,
      processStrategyGidToId('conversationId'),
    ]),
  }
)

export const graphqlError = new schema.Entity('graphqlError')

export const pinnedSearch = new schema.Entity(
  'pinnedSearch',
  {},
  {
    processStrategy: joinStrategies([processStrategyPinnedSearch]),
  }
)

export const session = new schema.Entity('session', {
  user: agent,
})

// btw, the entity name needs to match the variable name
export default {
  accessToken,
  account,
  agent,
  agentNotificationPreferences,
  attachment,
  author,
  batchJob,
  cannedReply,
  cannedReplyCategory,
  channel,
  companies,
  contacts,
  conversation,
  conversationEvent,
  conversationEventGroup,
  coupon,
  creditCard,
  customerRatingSetting,
  customFieldCategories,
  customFields,
  customFieldValueSubject,
  dataExport,
  defaultAgentNotificationPreferences,
  discount,
  emailMarketingIntegration,
  emailMarketingIntegrationMailboxSetting,
  facebookPage,
  feature,
  featureTemplate,
  featureTemplateCategory,
  folder,
  graphqlError,
  integration,
  integrationInstallStateProviderValue,
  invoice,
  message,
  pinnedSearch,
  plan,
  pricing,
  rule,
  ruleReplyTemplate,
  setupIntent,
  spammer,
  starredCannedReply,
  tag,
  team,
  usage,
  webhook,
  widget,
}
