import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import createCachedSelector from 're-reselect'
import { selectContactById } from './base'

export const selectContactFullNameOrYouByContactId = createCachedSelector(
  selectContactById,
  selectCurrentUser,
  (contact, currentUser) => {
    if (!contact) return null
    return contact.email === currentUser.email ? 'You' : contact.name
  }
)((_state, contactId) => contactId || 'unknown')
