import { createSelector } from 'reselect'

import {
  selectAllCustomFieldCategoriesHasErrored,
  selectAllCustomFieldCategoriesIsLoading,
} from 'ducks/crm/customFieldCategories/selectors/loading'
import { selectCurrentId } from 'selectors/location'
import createCachedSelector from 're-reselect'
import {
  selectCurrentEntities,
  selectCurrentEntitiesById,
} from 'ducks/entities/selectors'

export function selectBase(state) {
  return state.crm.customFields
}

export function selectCustomFieldsById(state) {
  return selectCurrentEntitiesById(state, 'customFields')
}

export function selectCustomFields(state) {
  return selectCurrentEntities(state, 'customFields')
}

export const selectCustomFieldKeys = createSelector(
  selectCustomFields,
  fields => fields.map(({ key }) => key)
)

export const selectCustomFieldKeysExcludingCurrentId = createSelector(
  selectCurrentId,
  selectCustomFields,
  (id, fields) => {
    return fields
      .filter(({ id: fieldId }) => id !== fieldId)
      .map(({ key }) => key)
  }
)

export const selectCustomFieldsByKey = createSelector(
  selectCustomFieldsById,
  customFields => {
    return Object.values(customFields).reduce((result, field) => {
      // eslint-disable-next-line no-param-reassign
      result[field.key] = field
      return result
    }, {})
  }
)

export function selectAllCustomFieldsIsLoading(state) {
  return selectBase(state).isLoading
}

export function selectAllCustomFieldsHasErrored(state) {
  return selectBase(state).hasErrored
}

export function selectAllCustomFieldsIsLoaded(state) {
  return selectBase(state).isLoaded
}

export const selectCustomFieldForId = createCachedSelector(
  selectCustomFieldsById,
  (_state, { id }) => id,
  (byId, id) => {
    return byId[id]
  }
)((_state, { id }) => id || 'unknown')

export const selectCustomFieldForKey = createCachedSelector(
  selectCustomFieldsByKey,
  (_state, { key }) => key,
  (byKey, key) => {
    return byKey[key]
  }
)((_state, { key }) => key || 'unknown')

export const selectCustomFieldWithHandleTypes = createSelector(
  selectCustomFields,
  customFields => customFields.filter(cf => !!cf.handleType)
)

export const selectIsCustomFieldsOrCategoriesLoading = createSelector(
  selectAllCustomFieldCategoriesIsLoading,
  selectAllCustomFieldsIsLoading,
  (categoriesIsLoading, fieldsIsLoading) => {
    return categoriesIsLoading || fieldsIsLoading
  }
)

export const selectCustomFieldsOrCategoriesHasErrored = createSelector(
  selectAllCustomFieldCategoriesHasErrored,
  selectAllCustomFieldsHasErrored,
  (categoriesIsErrored, fieldsIsErrored) => {
    return categoriesIsErrored || fieldsIsErrored
  }
)
