import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import {
  selectByQueryId,
  selectCurrentQueryOrderByOrDefault,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import createCachedSelector from 're-reselect'
import { selectCurrentTags } from 'ducks/tags/selectors'
import {
  queryIdToQuery,
  constructFolderItemQueryId,
  toBaseQueryId,
} from 'ducks/searches/utils/query'
import { TICKETS_PAGE } from 'constants/pages'
import { sortByKeyTypeDesc } from 'util/arrays'
import selectItemsById from './selectItemsById'
import selectItems from './selectItems'

export { selectItemsById, selectItems }

export const selectCurrentFolderItemQueryId = createSelector(
  selectCurrentQueryId,
  currentQueryId => {
    return toBaseQueryId(currentQueryId)
  }
)

export const selectCurrentFolderItem = createSelector(
  selectItems,
  selectCurrentFolderItemQueryId,
  (folderItems, currentFolderItemQueryId) => {
    return (
      folderItems.find(
        fi => toBaseQueryId(fi.queryId) === currentFolderItemQueryId
      ) || emptyObj
    )
  }
)

export const selectCurrentFolderName = createSelector(
  selectCurrentFolderItem,
  folderItem => folderItem.name || null
)

export const selectItemById = createCachedSelector(
  selectItemsById,
  (_state, itemId) => itemId,
  (itemsById, itemId) => {
    return itemsById[itemId] || emptyObj
  }
)((_state, itemId) => itemId || 'unknown')

export const selectLabelItems = createCachedSelector(
  selectByQueryId,
  selectCurrentTags,
  selectCurrentQueryOrderByOrDefault,
  (_state, channelId) => channelId,
  (_state, _channelId, channelType) => channelType,
  (byQueryId, tags, orderBy, channelId, channelType) => {
    return tags
      .map(label => {
        const { id: labelId } = label
        const labelWithGid = { ...label, gid: labelId }

        const itemQueryId = toBaseQueryId(
          constructFolderItemQueryId({
            channel: {
              id: channelId,
              channelType,
            },
            tagid: labelWithGid,
            state: 'open',
            orderBy,
          })
        )

        return {
          ...labelWithGid,
          itemQueryId,
          conversationCount: byQueryId[itemQueryId]?.entityCount || 0,
        }
      })
      .filter(label => label.conversationCount > 0)
      .sort(sortByKeyTypeDesc('conversationCount', 'number'))
      .map(label => {
        const { gid: labelId, name, itemQueryId } = label
        const itemId = `${channelId}_${labelId}`
        return {
          id: itemId,
          name,
          queryId: itemQueryId,
          linkTo: {
            type: TICKETS_PAGE,
            payload: {},
            meta: {
              query: queryIdToQuery(itemQueryId),
            },
          },
        }
      })
  }
)((_state, channelId, channelType) =>
  [channelId || 'unknown', channelType || 'unknown'].join('|')
)
