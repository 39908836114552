import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'

import {
  selectLatestRequestKey,
  selectRequestsByKey,
  selectResyncContactForIdAndIntegrationId,
} from './base'
import { selectCurrentContact, selectCurrentContactStates } from './current'
import { selectPage } from './page'
import { selectPageRequestKey } from './fetching'

export function selectPageHasErrored(state) {
  const page = selectPage(state)
  return page && page.errored
}

export function selectPageIsLoading(state, props) {
  const page = selectPage(state, props)
  return page && page.loading
}

export const selectPageIsOrWillBeLoading = createSelector(
  selectPage,
  selectLatestRequestKey,
  selectPageRequestKey,
  (page, latestRequestKey, requestKey) => {
    // If there is no page or the current request doesn't match the latest one
    // we can assume a component will load it soon.
    return !page || page.loading || latestRequestKey !== requestKey
  }
)

export const selectCurrentContactIsLoaded = createSelector(
  selectCurrentContact,
  contact => {
    const { isLoaded } = contact || emptyObj
    return !!isLoaded
  }
)

export const selectCurrentContactIsPartiallyLoaded = createSelector(
  selectCurrentContactStates,
  contact => {
    const { isLoaded, isPartiallyLoaded } = contact || emptyObj
    return !!isLoaded || !!isPartiallyLoaded
  }
)

export const selectCurrentContactIsLoading = createSelector(
  selectCurrentContact,
  ({ isLoading = false } = {}) => {
    if (isLoading === false) return false
    return true
  }
)

export const selectCurrentContactIsOrWillBeLoading = createSelector(
  selectCurrentContact,
  contact => {
    const { isLoading } = contact || emptyObj
    if (isLoading === false) return false
    return true
  }
)

export function selectIsLoadingForQuery(state, { query }) {
  return selectRequestsByKey(state)[query]?.loading
}

export function selectIsErroredForQuery(state, { query }) {
  return selectRequestsByKey(state)[query]?.errored
}

export const selectResyncContactForIdAndIntegrationIdIsLoading = createCachedSelector(
  selectResyncContactForIdAndIntegrationId,
  forIdAndIntegrationId => forIdAndIntegrationId?.isLoading || false
)(
  (state, integrationId, contactId) =>
    `${integrationId || 'unknown'}-${contactId || 'unknown'}`
)
