import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'

export const EMAIL_CHANNEL_TYPES = {
  google: CHANNEL_TYPE.GOOGLE,
  forwarding: CHANNEL_TYPE.FORWARDING,
  imap: CHANNEL_TYPE.IMAP,
  office365: CHANNEL_TYPE.OFFICE365,
  outlook: CHANNEL_TYPE.OFFICE365,
}

export const channelTypes = {
  livechat: 'widget',
  social: ['social', 'facebook', 'instagram'],
  forwarding: CHANNEL_TYPE.FORWARDING,
  google: CHANNEL_TYPE.GOOGLE,
  facebook: CHANNEL_TYPE.FACEBOOK,
  instagram: 'instagram',
  twitter: CHANNEL_TYPE.TWITTER,
  chat: ['social', 'widget', 'facebook', 'instagram'],
  inboxes: [...Object.keys(EMAIL_CHANNEL_TYPES), 'twitter'],
  inboxOAuthes: Object.keys(EMAIL_CHANNEL_TYPES).filter(
    type => type !== CHANNEL_TYPE.FORWARDING
  ),
}

export const keyFromChannelType = channelType => {
  if (!Array.isArray(channelType)) return channelType

  return channelType.join('-')
}

export const isChatChannelType = channelType => {
  return channelTypes.chat.includes(channelType)
}

export const isBridgeChannelType = channelType => {
  return channelTypes.social.includes(channelType)
}

export const isEmailChannelType = channelType => {
  return channelTypes.inboxes.includes(channelType)
}

export const isEmailOAuthChannelType = channelType => {
  return channelTypes.inboxOAuthes.includes(channelType)
}

export const isIMAPChannelType = channelType =>
  [EMAIL_CHANNEL_TYPES.imap].includes(channelType?.toUpperCase?.())

export const isEmailEngineChannelType = channelType => {
  return [
    CHANNEL_TYPE.IMAP,
    CHANNEL_TYPE.OFFICE365,
    CHANNEL_TYPE.GOOGLE,
  ].includes(channelType?.toUpperCase?.())
}
