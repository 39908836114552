import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import {
  selectCurrentEntitiesById,
  selectCurrentEntities,
  selectCurrentEntityById,
  selectPendingEntitiesByIds,
} from 'ducks/entities/selectors'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { createBasicSelector } from 'util/redux'

import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { sortByKey } from 'util/arrays/sorting'
import { getRawId } from 'util/globalId'
import {
  isAgentNotArchived,
  isAgentInvitationAccepted,
  isRoleOneOf,
} from './utils'
import { PAID_AGENT_ROLES } from './constants'

export const selectCurrentAgentsById = state =>
  selectCurrentEntitiesById(state, 'agent') || emptyObj

export const selectCurrentUnarchivedAgentsById = createSelector(
  selectCurrentAgentsById,
  agentsById =>
    Object.keys(agentsById).reduce((activeAgentsById, agentId) => {
      const agent = agentsById[agentId]
      if (isAgentNotArchived(agent)) {
        // eslint-disable-next-line no-param-reassign
        activeAgentsById[agentId] = agent
      }
      return activeAgentsById
    }, {})
)

export const selectCurrentAgentById = (state, id) =>
  selectCurrentEntityById(state, 'agent', getRawId(id)) || null

export const selectCurrentAgentEntities = createBasicSelector(
  state => selectCurrentEntities(state, 'agent') || emptyArr
)

export const selectCurrentAgents = createCachedSelector(
  selectCurrentAgentEntities,
  selectCurrentUser,
  (_state, options = {}) => options.sortBy || 'name',
  (_state, options = {}) => !!options.sortCurrentFirst,
  (_state, options = {}) =>
    [true, null, undefined].includes(options.includeArchived),
  (_state, options = {}) =>
    [true, null, undefined].includes(options.includeInvited),
  (_state, options = {}) => options.byRoles,
  (
    agents,
    currentUser,
    sortBy,
    sortCurrentFirst,
    includeArchived,
    includeInvited,
    byRoles
  ) => {
    return agents
      .filter(a => {
        const filters = []
        if (!includeArchived) {
          filters.push(isAgentNotArchived)
        }
        if (!includeInvited) {
          filters.push(isAgentInvitationAccepted)
        }
        if (byRoles) {
          filters.push(isRoleOneOf)
        }
        return filters.reduce(
          (isValid, filterFn) => isValid && filterFn(a, byRoles),
          true
        )
      })
      .sort((a, b) => {
        if (sortCurrentFirst) {
          if (a.id === currentUser.id) {
            return -1
          } else if (b.id === currentUser.id) {
            return 1
          }
        }
        // eslint-disable-next-line no-nested-ternary
        return a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0
      })
  }
)(
  (
    _state,
    {
      sortCurrentFirst = false,
      includeArchived = true,
      includeInvited = true,
      byRoles = [],
    } = {}
  ) =>
    `${sortCurrentFirst}-${includeArchived}-${includeInvited}-${byRoles.join(
      '|'
    )}`
)

export const selectCurrentActiveOrInvitedAgents = createBasicSelector(state =>
  selectCurrentAgents(state, {
    sortCurrentFirst: false,
    includeArchived: false,
    includeInvited: true,
  })
)

export const selectCurrentPaidActiveAgents = createBasicSelector(state =>
  selectCurrentAgents(state, {
    sortCurrentFirst: false,
    includeArchived: false,
    includeInvited: false,
    byRoles: PAID_AGENT_ROLES,
  })
)

export const selectCurrentPaidActiveAgentsWithCurrentUserSort = createBasicSelector(
  state =>
    selectCurrentAgents(state, {
      sortCurrentFirst: true,
      includeArchived: false,
      includeInvited: false,
      byRoles: PAID_AGENT_ROLES,
    })
)

const selectCurrentTeams = state =>
  selectCurrentEntities(state, 'team') || emptyArr

export const selectCurrentUnarchivedAgentsByTeamId = createSelector(
  selectCurrentTeams,
  selectCurrentUnarchivedAgentsById,
  (teams, agentsById) => {
    return teams.reduce((byTeamId, team) => {
      sortByKey(team.agents, 'id').forEach(agent => {
        if (!byTeamId[team.id]) {
          // eslint-disable-next-line no-param-reassign
          byTeamId[team.id] = []
        }

        // Archived users could still be in the agents array of the team
        if (agentsById[agent.id]) {
          byTeamId[team.id].push(agentsById[agent.id])
        }
      })
      return byTeamId
    }, {})
  }
)

export const selectCurrentUnarchivedAgentsFilteredByTeamId = createCachedSelector(
  selectCurrentUnarchivedAgentsByTeamId,
  (_state, teamId) => teamId,
  (agentsByTeamId, teamId) => agentsByTeamId[teamId] || emptyArr
)((_state, teamId) => teamId)

export const selectPendingAgentsByIds = (state, ids) =>
  selectPendingEntitiesByIds(state, 'agent', ids)

export const selectCurrentAgentsUsernames = createSelector(
  selectCurrentAgents,
  agents => agents.map(agent => agent.username)
)
