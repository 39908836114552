import { UPDATE_APP_DATA, FETCH_AGENTS_SUCCESS } from 'constants/action_types'
import { FETCH_FOLDERS_V2_SUCCESS } from 'ducks/folders/actionTypes/folders'
import { FETCH_GROUPS_SUCCESS } from 'constants/actionTypes/groups'
import { FETCH_ACCOUNT_SUCCESS } from 'constants/actionTypes/account'
import { FETCH_WIDGETS_SUCCESS } from 'ducks/widgets/types'
import { FETCH_CURRENT_USER_SUCCESS } from 'ducks/currentUser/types'
import {
  FETCH_BILLING_DATA_V1_SUCCESS,
  FETCH_BILLING_SUCCESS,
  APP_BILLING_EXPIRED,
} from 'ducks/billing/types'
import { FETCH_INTEGRATIONS_SUCCESS } from 'ducks/integrations/types'
import { FETCH_TAGS_SUCCESS } from 'ducks/tags/actionTypes'
import { FETCH_CHANNELS_SUCCESS } from 'ducks/channels/actionTypes'
import { BOOTSTRAP_APP_DATA_SUCCESS } from './types'
import { STATES } from './constants'

const defaultState = {
  agents: STATES.INITIAL,
  billing: STATES.INITIAL,
  groups: STATES.INITIAL,
  account: STATES.INITIAL,
  widgets: STATES.INITIAL,
  folders: STATES.INITIAL,
  labels: STATES.INITIAL,
  mailboxes: STATES.INITIAL,
  currentUser: STATES.INITIAL,
  integrations: STATES.INITIAL,
}

const reducers = {
  UPDATE_APP_DATA: [],
  BOOTSTRAP_APP_DATA_SUCCESS: [],
}

function createBootstrapLoadedReducer(key, checkFields) {
  return (state, action) => {
    // action.data is for backward compatibility. Some of the bootstrap
    // actions still use data instead of payload as the parameter name
    const payload = action.payload || action.data
    const fields = checkFields.map(field => payload[field])

    const isValid = fields.every(fieldData => fieldData !== undefined)
    if (isValid) {
      return {
        ...state,
        [key]: STATES.LOADED,
      }
    }
    return state
  }
}

const agentsReducer = createBootstrapLoadedReducer('agents', ['agents'])
reducers[UPDATE_APP_DATA].push(agentsReducer)
reducers[FETCH_AGENTS_SUCCESS] = agentsReducer

const groupsReducer = createBootstrapLoadedReducer('groups', ['groups'])
reducers[UPDATE_APP_DATA].push(groupsReducer)
reducers[FETCH_GROUPS_SUCCESS] = groupsReducer

const accountReducer = createBootstrapLoadedReducer('account', ['account'])
reducers[UPDATE_APP_DATA].push(accountReducer)
reducers[APP_BILLING_EXPIRED] = accountReducer
reducers[FETCH_ACCOUNT_SUCCESS] = accountReducer

const widgetsReducer = createBootstrapLoadedReducer('widgets', ['widgets'])
reducers[FETCH_WIDGETS_SUCCESS] = widgetsReducer

const foldersReducer = createBootstrapLoadedReducer('folders', ['folders'])
reducers[BOOTSTRAP_APP_DATA_SUCCESS].push(foldersReducer)
reducers[FETCH_FOLDERS_V2_SUCCESS] = foldersReducer

const mailboxesReducer = createBootstrapLoadedReducer('mailboxes', ['channels'])

reducers[FETCH_CHANNELS_SUCCESS] = mailboxesReducer

const labelsReducer = createBootstrapLoadedReducer('labels', ['labels'])
reducers[UPDATE_APP_DATA].push(labelsReducer)
reducers[FETCH_TAGS_SUCCESS] = labelsReducer

const currentUserReducer = createBootstrapLoadedReducer('currentUser', [
  'currentUser',
])
reducers[UPDATE_APP_DATA].push(currentUserReducer)
reducers[FETCH_CURRENT_USER_SUCCESS] = currentUserReducer

const billingReducer = createBootstrapLoadedReducer('billing', ['billing'])
reducers[UPDATE_APP_DATA].push(billingReducer)
reducers[FETCH_BILLING_DATA_V1_SUCCESS] = billingReducer
reducers[FETCH_BILLING_SUCCESS] = billingReducer

const integrationsReducer = createBootstrapLoadedReducer('integrations', [
  'integrations',
])
reducers[BOOTSTRAP_APP_DATA_SUCCESS].push(integrationsReducer)
reducers[BOOTSTRAP_APP_DATA_SUCCESS].push(mailboxesReducer)
reducers[FETCH_INTEGRATIONS_SUCCESS] = integrationsReducer

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  let handlers = reducers[action.type]
  if (handlers) {
    if (!Array.isArray(handlers)) {
      handlers = [handlers]
    }
    const finalState = handlers.reduce((currentState, handler) => {
      return handler(currentState, action)
    }, state)
    return finalState
  }
  return state
}
