import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import {
  selectCurrentFolderById,
  selectCurrentFolders,
} from 'ducks/folders/selectors/folders'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildIdFromAny } from 'util/globalId'
import { USERS_RANGES } from 'subapps/settings/components/UserRestrictionTypeDropdown'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { selectCustomFieldsByKey } from 'ducks/crm/customFields/selectors/base'
import {
  FETCH_FOLDERS_V2,
  FETCH_FOLDERS_BY_IDS_V2,
  DELETE_FOLDER,
  UPDATE_FOLDER,
  CREATE_FOLDER,
  FETCH_FOLDER_CHANNELS,
} from '../actionTypes/folders'
import {
  dataTableQuery,
  getByIdsQuery,
  getChannelsQuery,
} from '../queries/folders/queries.v2'
import {
  folderGraphQlV2ResponseSchema,
  folderGraphQlCreateResponseSchema,
  folderGraphQlUpdateResponseSchema,
} from '../schema/folders'
import { calculateFolderAccess } from '../utils/calculateFolderAccess'

const ENTITY_TYPE = 'folder'

const LOAD_ALL_FOLDERS_QUERYID = 'entityType:folder pageSize:10000'
export const doFetchFolders = () => dispatch => {
  const queryId = LOAD_ALL_FOLDERS_QUERYID
  const cursor = 'all'

  return dispatch(
    doGraphqlRequest(
      FETCH_FOLDERS_V2,
      dataTableQuery(),
      {
        size: 1000,
      },
      {
        app: true,
        normalizationSchema: folderGraphQlV2ResponseSchema,
        searches: {
          queryId,
          cursor,
          extractPagination: 'gqlv2',
        },
        transformResponse: calculateFolderAccess,
      }
    )
  )
}

export const doFetchFoldersInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_FOLDERS_QUERYID,
  entityType: 'folder',
  doLoadAllFn: doFetchFolders,
})

export const deleteFolderByIdMutation = () => `
  mutation FolderDelete(
    $folderId: ID!,
  ) {
    folderDelete(
      input: {
        folderId: $folderId,
      }
    ) {
      deletedFolderId
      errors {
        path
        message
      }
    }
  }
`

export const doDeleteFolderById = (id, options = {}) => dispatch => {
  return dispatch(
    doGraphqlRequest(
      DELETE_FOLDER,
      deleteFolderByIdMutation(),
      {
        folderId: id,
      },
      {
        app: true,
        optimist: {},
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `${app.t('Folder')} deletion complete`,
            },
            failed: {
              content: `${app.t('Folder')} deletion failed`,
              onClickAction: () => {
                dispatch(doDeleteFolderById(id, options))
              },
            },
          },
        },
        entities: {
          targetOperation: 'remove',
          additionalActions: [
            {
              entityType: ENTITY_TYPE,
              entityId: id,
              stores: ['pending', 'current'],
              operation: 'remove',
              phases: ['STARTED', 'SUCCESS'],
            },
          ],
        },
      }
    )
  )
}

const preparePayload = (folder, { getState, updatePosition }) => {
  const currentUserId = selectCurrentUserId(getState())
  const agentIds =
    folder.permittedAgentIds || folder.agents?.map(a => a.id) || null
  const teamIds =
    folder.permittedTeamIds || folder.teams?.map(t => t.id) || null
  const channelIds = folder.channelIds || folder.channels?.map(c => c.id)
  const customFieldsByKey = selectCustomFieldsByKey(getState())

  const conditions = folder.conditions.map(c => {
    const condition = { ...c }
    if (c.param === 'CUSTOM_FIELD') {
      condition.sourceId = condition.source.id
    }
    delete condition.source

    if (!c.param.startsWith('CUSTOM_FIELD_')) return condition

    return Object.assign(condition, {
      param: 'CUSTOM_FIELD',
      sourceId: customFieldsByKey[c.param.replace('CUSTOM_FIELD_', '')].id,
    })
  })

  const payload = {
    ...folder,
    folderId: folder.id,
    permittedAgentIds: agentIds?.map(id => buildIdFromAny('Agent', id)),
    permittedTeamIds: teamIds?.map(id => buildIdFromAny('Team', id)),
    channelIds: channelIds?.map(id => buildIdFromAny('Channel', id)),
    channels: null,
    teams: null,
    agents: null,
    conditions,
  }
  if (folder.viewAccess === USERS_RANGES.TEAMS) {
    payload.permittedAgentIds = []
  } else if (folder.viewAccess === USERS_RANGES.AGENTS) {
    payload.permittedTeamIds = []
  } else if (folder.viewAccess === USERS_RANGES.ME) {
    payload.permittedTeamIds = []
    payload.permittedAgentIds = [currentUserId]
  } else {
    payload.permittedAgentIds = []
    payload.permittedTeamIds = []
  }
  if (!updatePosition) {
    delete payload.position
  }
  return payload
}

const createFolderMutation = () => `
mutation FolderCreate(
  $name: String!,
  $description: String,
  $matchType: FolderMatchTypes!,
  $state: FolderState!,
  $position: Int,
  $displayCountWhenInactive: Boolean,
  $hideIfZeroConversations: Boolean,
  $permittedAgentIds: [ID!],
  $permittedTeamIds: [ID!],
  $channelIds: [ID!],
  $conditions: [ConditionInput!]!
) {
  folderCreate(
    input: {
      name: $name,
      description: $description,
      matchType: $matchType,
      state: $state,
      position: $position,
      displayCountWhenInactive: $displayCountWhenInactive,
      hideIfZeroConversations: $hideIfZeroConversations,
      permittedAgentIds: $permittedAgentIds,
      permittedTeamIds: $permittedTeamIds,
      channelIds: $channelIds,
      conditions: $conditions
    }
  ) {
    folder {
      id
      name
    }
    errors {
      path
      message
    }
  }
}
`

// eslint-disable-next-line no-unused-vars
export const doCreateFolder = (folder, options = {}) => (
  dispatch,
  getState
) => {
  const entityType = 'folder'

  const payload = preparePayload(folder, { getState })

  return dispatch(
    doGraphqlRequest(CREATE_FOLDER, createFolderMutation(), payload, {
      app: true,
      throwOnError: true,
      normalizationSchema: folderGraphQlCreateResponseSchema,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: [entityType],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: `${app.t('Folder')} created`,
          },
          failed: {
            content: `${app.t('Folder')} create failed`,
            onClickAction: () => dispatch(doCreateFolder(folder, options)),
          },
        },
      },
    })
  )
}

function calculateFolderPosition(folder, currentOrderedFolders) {
  if (currentOrderedFolders[folder.position + 1]) {
    return currentOrderedFolders[folder.position + 1].position - 10
  } else if (currentOrderedFolders[folder.position - 1]) {
    return currentOrderedFolders[folder.position - 1].position + 10
  }
  return folder.position
}

const updateFolderMutation = () => `
mutation FolderUpdate(
  $folderId: ID!,
  $name: String!,
  $description: String,
  $matchType: FolderMatchTypes!,
  $state: FolderState!,
  $position: Int,
  $displayCountWhenInactive: Boolean,
  $hideIfZeroConversations: Boolean,
  $permittedAgentIds: [ID!],
  $permittedTeamIds: [ID!],
  $channelIds: [ID!],
  $conditions: [ConditionInput!]!
) {
  folderUpdate(
    input: {
      folderId: $folderId,
      name: $name,
      description: $description,
      matchType: $matchType,
      state: $state,
      position: $position,
      displayCountWhenInactive: $displayCountWhenInactive,
      hideIfZeroConversations: $hideIfZeroConversations,
      permittedAgentIds: $permittedAgentIds,
      permittedTeamIds: $permittedTeamIds,
      channelIds: $channelIds,
      conditions: $conditions
    }
  ) {
    folder {
      id
      name
    }
    errors {
      path
      message
    }
  }
}
`

// eslint-disable-next-line no-unused-vars
export const doUpdateFolder = (folder, options = {}) => (
  dispatch,
  getState
) => {
  const { updatePosition = false, queryId, orderedIds = null } = options
  const id = folder.id

  const state = getState()

  const stateFolder = selectCurrentFolderById(state, id)

  const orderedFolders = selectCurrentFolders(state)

  const payload = preparePayload(folder, { getState, updatePosition })

  const entityId = folder.id
  const entityType = 'folder'
  const optimisticFolder = {
    ...stateFolder,
  }
  if (updatePosition) {
    optimisticFolder.position = calculateFolderPosition(folder, orderedFolders)
  }

  const optimist = {
    entities: {
      [entityType]: {
        [entityId]: optimisticFolder,
      },
    },
  }

  return dispatch(
    doGraphqlRequest(UPDATE_FOLDER, updateFolderMutation(), payload, {
      app: true,
      throwOnError: true,
      optimist,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: [entityType],
            phases: ['SUCCESS'],
          },
          {
            type: 'UPDATE_CURSOR',
            queryId,
            entityIds: orderedIds,
            phases: ['STARTED'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: !updatePosition,
            content: `${app.t('Folder')} updated`,
          },
          failed: {
            content: `${app.t('Folder')} update failed`,
            onClickAction: () => dispatch(doUpdateFolder(folder, options)),
          },
        },
        entities: {
          additionalActions: [
            {
              // For updates
              // Clear pending entity after successful entity update
              entityType,
              entityId: id,
              stores: ['pending'],
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
      },
      normalizationSchema: folderGraphQlUpdateResponseSchema,
    })
  )
}

const fetchFolderChannels = async props => {
  const { data, variables, cursor, dispatch } = props
  const targetFolder = data.folders?.nodes[0]
  if (!targetFolder) return data
  const { nodes, pageInfo } = targetFolder.channels
  const { hasNextPage, endCursor } = pageInfo
  if (hasNextPage) {
    const moreData = await dispatch(
      doGraphqlRequest(
        FETCH_FOLDER_CHANNELS,
        getChannelsQuery(),
        {
          ...variables,
          channelsCursor: endCursor,
        },
        {
          app: true,
          searches: {
            cursor,
            extractPagination: 'gqlv2',
          },
        }
      )
    )
    targetFolder.channels.pageInfo = moreData.folders.nodes[0].channels.pageInfo
    if (moreData.folders.nodes[0].channels.nodes.length) {
      nodes.push(...moreData.folders.nodes[0].channels.nodes)
      await fetchFolderChannels(props)
    }
  }
  return data
}

export const doFetchFolderByIdsV2 = (ids, options = {}) => dispatch => {
  // targetStore: optional, defaults to changeEntity()'s default store otherwise
  const { targetStore, isUpdate } = options

  const missingEntityActions = ids.map(id => {
    return {
      entityType: 'cannedReply',
      entityId: id,
    }
  })

  const variables = {
    filter: { ids, state: 'ALL', scope: 'ALL' },
  }

  const cursor = 'all'
  return dispatch(
    doGraphqlRequest(FETCH_FOLDERS_BY_IDS_V2, getByIdsQuery(), variables, {
      app: true,
      normalizationSchema: folderGraphQlV2ResponseSchema,
      searches: {
        cursor,
        extractPagination: 'gqlv2',
      },
      transformResponse: async (data, ...rest) => {
        let result = data
        // Fetch all channels for folder edit
        if (ids.length === 1 && isUpdate) {
          result = await fetchFolderChannels({
            data,
            variables,
            cursor,
            dispatch,
          })
        }

        return calculateFolderAccess(result, ...rest)
      },
      moduleOptions: {
        entities: {
          targetStore,
          missingEntityActions,
        },
      },
    })
  )
}

export const doDeleteFolders = (ids, options = {}) => dispatch => {
  // Promise.all is not technically required in the current implementation because
  // we'll only step into this block if the ids array has a single id, but I do
  // want to leave the door open for us to say that deleting less than X rows uses
  // single deletes instead of batch deletes
  return Promise.all(ids.map(id => dispatch(doDeleteFolderById(id, options))))
}
