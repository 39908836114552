import { selectCurrentContactIdOrTemp } from 'ducks/crm/contacts/selectors/current'
import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { changeEntity, mergeEntityChanges } from 'ducks/entities/actionUtils'
import { selectCanPageOnClient } from '../../selectors'
import { selectCustomFieldIdPages } from '../../selectors/customFieldLoadOrder'
import { conversationContactNormalizationSchema } from '../../schema'
import {
  FETCH_CONVERSATION_CONTACT_PAGE,
  FETCH_CONVERSATION_CONTACT_STARTED,
  FETCH_CONVERSATION_CONTACT_SUCCESS,
} from '../../types'
import { fetchConversationContactCustomFieldsFirstPageQuery } from './fetchConversationContactCustomFieldsFirstPageQuery'
import { fetchConversationContactCustomFieldsQuery } from './fetchConversationContactCustomFieldsQuery'
import { makeEntitiesAvailable } from './makeEntitiesAvailable'
import { fetchConversationContactCustomFieldsClientPagingQuery } from './fetchConversationContactCustomFieldsClientPagingQuery'
import { fetchConversationContactCustomFieldsFirstPageClientPagingQuery } from './fetchConversationContactCustomFieldsFirstPageClientPagingQuery'

export function doFetchContactForConversationId(conversationId) {
  return async (dispatch, getState) => {
    const state = getState()
    const contactId = selectCurrentContactIdOrTemp(state)

    let hasNextPage
    let after
    let fetchedContactId
    let page = 0
    const canPageOnClient = selectCanPageOnClient(state)
    const { contact: contactCustomFieldIdPages = [] } = canPageOnClient
      ? selectCustomFieldIdPages(state)
      : {}
    const fetchFirstPageQuery = canPageOnClient
      ? fetchConversationContactCustomFieldsFirstPageClientPagingQuery
      : fetchConversationContactCustomFieldsFirstPageQuery
    const fetchQuery = canPageOnClient
      ? fetchConversationContactCustomFieldsClientPagingQuery
      : fetchConversationContactCustomFieldsQuery
    dispatch({
      type: FETCH_CONVERSATION_CONTACT_STARTED,
      ...mergeEntityChanges([
        contactId &&
          changeEntity('contacts', contactId, {
            [contactId]: {
              id: contactId,
              isLoading: true,
              isCreating: false,
            },
          }),
        contactId && {
          entities: {
            contacts: {
              [contactId]: {
                id: contactId,
                isLoading: true,
                isCreating: false,
              },
            },
          },
        },
      ]),
      meta: { mergeEntities: true },
    })
    do {
      const variables = {
        after,
        contactCustomFieldIds: contactCustomFieldIdPages[page],
        number: parseInt(conversationId, 10),
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_CONVERSATION_CONTACT_PAGE,
          page === 0 ? fetchFirstPageQuery : fetchQuery,
          variables,
          {
            meta: { mergeEntities: true },
            normalizationSchema: conversationContactNormalizationSchema,
            transformEntities: canPageOnClient ? makeEntitiesAvailable : null,
          }
        )
      )
      const { conversation: { contact } = {} } = result || {}
      if (!contact) {
        return dispatch({
          type: FETCH_CONVERSATION_CONTACT_SUCCESS,
          ...mergeEntityChanges([
            changeEntity('contacts', contactId, {
              id: contactId,
              isPartiallyLoaded: true,
              isLoaded: true,
              isMissing: true,
              isLoading: false,
              isCreating: false,
            }),
            {
              entities: {
                contacts: {
                  [contactId]: {
                    id: contactId,
                    isPartiallyLoaded: true,
                    isLoaded: true,
                    isMissing: true,
                    isLoading: false,
                    isCreating: false,
                  },
                },
              },
            },
          ]),
          meta: { mergeEntities: true },
        })
      }
      fetchedContactId = contact.id
      const { customFieldValues } = contact || {}
      const { pageInfo } = customFieldValues || {}
      hasNextPage = pageInfo?.hasNextPage
      after = pageInfo && pageInfo.endCursor
      page += 1
    } while (canPageOnClient ? contactCustomFieldIdPages[page] : hasNextPage)

    dispatch({
      type: FETCH_CONVERSATION_CONTACT_SUCCESS,
      ...mergeEntityChanges([
        changeEntity('contacts', contactId, {
          id: contactId,
          isPartiallyLoaded: true,
          isLoaded: true,
          isMissing: false,
          isLoading: false,
          isCreating: false,
        }),
        contactId && {
          entities: {
            contacts: {
              [contactId]: {
                id: contactId,
                isPartiallyLoaded: true,
                isLoaded: true,
                isMissing: false,
                isLoading: false,
                isCreating: false,
              },
              [fetchedContactId]: {
                id: fetchedContactId,
                isPartiallyLoaded: true,
                isLoaded: true,
                isMissing: false,
                isLoading: false,
                isCreating: false,
              },
            },
          },
        },
      ]),
      meta: { mergeEntities: true, contactId },
    })
    return fetchedContactId
  }
}
