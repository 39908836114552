import { isEmpty as isEmptyArray, uniqByProp } from 'util/arrays'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { selectFolderIdsMatchingDraftForCurrentUser } from 'ducks/folders/selectors/folders/selectFolderIdsMatchingDraftForCurrentUser'
import { isFacebook, isTwitter } from 'ducks/tickets/utils/type'
import { FETCH_DRAFTS } from './constants'

export const isSaveable = draft => {
  const wasSavedBefore = !!draft.serverVersion
  if (wasSavedBefore) return true
  const bodyIsEmpty = !draft.body || draft.body === ''
  const hasAttachments =
    draft.attachmentsNormalized && draft.attachmentsNormalized.length > 0
  if (bodyIsEmpty && !hasAttachments) return false
  return true
}

export const getUniqueRecipients = (original, merged) => {
  const getArray = value => (isEmptyArray(value) ? [] : value)
  return uniqByProp([...getArray(original), ...getArray(merged)], 'id')
}

export const updateToWithRecipientName = (to, value = '') => {
  const recipient = to?.[0]
  if (!recipient) return to

  const parts = value.split(' ')
  const newTo = [
    {
      ...recipient,
      name: value,
      ...(parts[0] && { first_name: parts[0] }),
      ...(parts[1] && { last_name: parts[1] }),
    },
    ...((to && to.slice(1)) || []),
  ]

  return newTo
}

export const buildSharedDraftActionMetadata = state => {
  const draftFolderIds = selectFolderIdsMatchingDraftForCurrentUser(state)
  const currentUserId = selectCurrentUserId(state)

  return {
    draftFolderIds,
    currentUserId,
  }
}

export const fetchDraftsActionRequestKey = (ticketId, draftType) => {
  return `${FETCH_DRAFTS}/${draftType}/${ticketId}`
}

// Check src/ducks/drafts2/__tests__/formatEmailAddressesStringForEmailParser.test.js
export const formatEmailAddressesStringForEmailParser = emailAddressesString =>
  emailAddressesString?.trim().replace(/,?\s+/g, ',')

export const firstEnduserActorFromDenormalizedEventGroup = eventGroup => {
  if (!eventGroup) return null
  // eslint-disable-next-line no-underscore-dangle
  return eventGroup.actor.__typename === 'Agent'
    ? eventGroup.summary?.to?.[0]
    : eventGroup.actor
}

export function isUndoable(draft, ticket) {
  if (!draft) return false
  if (draft.isForwarding && draft.isNote) return false
  if (isFacebook(ticket) || isTwitter(ticket)) return false
  return true
}
