import React from 'react'

import { Overview16 } from 'ducks/reports/overviewLayouts'

import ConversationsCreatedPerDayHeadline from './ConversationsCreatedPerDayHeadline'
import ConversationsResolvedPerDayHeadline from './ConversationsResolvedPerDayHeadline'
import ConversationsCustomersResolutionsForEachDay from './ConversationsCustomersResolutionsForEachDay'
import CustomersPerDayHeadline from './CustomersPerDayHeadline'
import NetDiffOpenConversations from './NetDiffOpenConversations'
import TotalConversationsCreatedHeadline from './TotalConversationsCreatedHeadline'
import TotalConversationsResolvedHeadline from './TotalConversationsResolvedHeadline'
import TotalCustomersHeadline from './TotalCustomersHeadline'

export default function ConversationsOverview({ queryBatch, queryBase }) {
  return (
    <Overview16
      title="Conversations"
      components={[
        <ConversationsCustomersResolutionsForEachDay
          queryBatch={queryBatch}
          queryBase={queryBase}
        />,
        <NetDiffOpenConversations
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        null,
        <TotalConversationsCreatedHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <ConversationsCreatedPerDayHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <TotalConversationsResolvedHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <ConversationsResolvedPerDayHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <TotalCustomersHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <CustomersPerDayHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
      ]}
    />
  )
}
