import { selectCurrentChannels } from 'ducks/channels/selectors'
import { CUSTOM_FIELD_CATEGORY_TYPE_CHANNEL } from 'ducks/crm/customFieldCategories/constants'
import { selectCustomFields } from 'ducks/crm/customFields/selectors/base'
import { createSelector } from 'reselect'
import { convertGidToRaw } from 'util/scatterSwap'
import { MAILBOX_CUSTOM_FIELD_PREFIX } from '../constants'

export const selectChannelCustomFieldCategoryGroups = createSelector(
  selectCurrentChannels,
  selectCustomFields,
  (channels, customFields) => {
    return channels
      .map(c => {
        const fields = customFields
          .filter(cf => !cf.deletedAt)
          .filter(cf => !!c.customFields?.includes(cf.id))
          .map(cf => ({
            ...cf,
            id: cf.key,
          }))
        const categoryKey = `${MAILBOX_CUSTOM_FIELD_PREFIX}${convertGidToRaw(
          c.id
        )}`
        if (fields.length === 0) return null
        return {
          id: categoryKey,
          key: categoryKey,
          name: c.name,
          type: CUSTOM_FIELD_CATEGORY_TYPE_CHANNEL,
          deletable: true,
          createdAt: new Date(),
          fields,
        }
      })
      .filter(x => !!x)
  }
)
