import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectMailboxesIncludingInaccessible } from 'ducks/mailboxes/selectors/selectMailboxesIncludingInaccessible'
import { selectGroupsFilteredByCurrentUserGroupsAccess } from 'selectors/app/groups_filtered'
import { buildId, getRawId } from 'util/globalId'
import { USERS_RANGES } from 'subapps/settings/components/UserRestrictionTypeDropdown'
import { getFolderKind } from './getFolderKind'

export function calculateFolderAccess(data, { getState }) {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const totalChannels = selectMailboxesIncludingInaccessible(state).length
  const groupsWithCurrentUserAccess = selectGroupsFilteredByCurrentUserGroupsAccess(
    state
  )
  const {
    agents: { nodes: agents },
    folders: { nodes: folders },
  } = data
  const totalAgents = agents.length

  folders.forEach(folder => {
    const {
      channels: { totalCount: totalFolderChannelCount },
      teams: { nodes: folderTeams },
      agents: { nodes: folderAgents },
    } = folder

    const linkType =
      folderTeams.length > 0 && folderAgents.length === 0 ? 'team' : 'agent'

    const linkedAllAgents = totalAgents === folderAgents.length
    let viewAccess
    if (linkType === 'team') {
      viewAccess = USERS_RANGES.TEAMS
    } else {
      viewAccess = linkedAllAgents ? USERS_RANGES.EVERYONE : USERS_RANGES.AGENTS
    }

    const currentUserGlobalId = buildId('Agent', currentUser.id)
    if (folderAgents.length === 1) {
      const id = folderAgents[0].id
      if (id === currentUserGlobalId) viewAccess = USERS_RANGES.ME
    }

    let hasAccess
    if (linkType === 'agent') {
      hasAccess = folderAgents.some(fa => fa.id === currentUserGlobalId)
    } else {
      hasAccess = folderTeams.some(ft =>
        groupsWithCurrentUserAccess.some(g => g.id === getRawId(ft.id))
      )
    }

    Object.assign(folder, {
      channelVisibility:
        totalChannels === totalFolderChannelCount ? 'all' : 'selected',
      viewAccess,
      hasAccess,
      kind: getFolderKind(folder, { currentUserId: currentUser.id }),
    })
  })

  Object.assign(data, {
    currentUser,
  })

  return data
}
