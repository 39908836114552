import { selectCurrentChannels } from 'ducks/channels/selectors'
import { selectCustomFields } from 'ducks/crm/customFields/selectors/base'
import { createSelector } from 'reselect'
import { flatten, uniq } from 'util/arrays'

export const selectChannelCustomFields = createSelector(
  selectCurrentChannels,
  selectCustomFields,
  (channels, customFields) => {
    return uniq(
      flatten(
        channels.map(c => {
          return customFields
            .filter(cf => !cf.deletedAt)
            .filter(cf => !!c.customFields?.includes(cf.id))
        })
      )
    )
  }
)
