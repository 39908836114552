import {
  OPERATOR_TYPE_EQ,
  OPERATOR_TYPE_LT,
  OPERATOR_TYPE_LTEQ,
  OPERATOR_TYPE_NOT_EQ,
} from 'util/operators'

export function buildFolderKindsTypes({ currentUserId }) {
  return [
    {
      type: 'unassigned',
      conditions: [
        {
          param: 'assigned_agent',
          operators: [OPERATOR_TYPE_EQ],
          value: [null],
        },
        {
          param: 'assigned_group',
          operators: [OPERATOR_TYPE_EQ],
          value: [null],
        },
        {
          param: 'status',
          operators: [OPERATOR_TYPE_LTEQ, OPERATOR_TYPE_EQ],
          value: ['opened'],
        },
      ],
    },
    currentUserId && {
      type: 'mine',
      conditions: [
        {
          param: 'assigned_agent',
          operators: [OPERATOR_TYPE_EQ],
          // 4517239960 is scatterswap for -1
          value: [currentUserId, '-1', '4517239960'],
        },
        {
          param: 'status',
          operators: [OPERATOR_TYPE_LTEQ, OPERATOR_TYPE_EQ],
          value: ['opened'],
        },
      ],
    },
    {
      type: 'closed',
      conditions: [
        {
          param: 'status',
          operators: [OPERATOR_TYPE_EQ],
          value: 'closed',
        },
        {
          param: 'snooze_until',
          operators: [OPERATOR_TYPE_LT],
          value: ['indefinitely'],
        },
      ],
    },
    {
      type: 'closed',
      conditions: [
        {
          param: 'status',
          operators: [OPERATOR_TYPE_EQ],
          value: ['closed'],
        },
      ],
    },
    currentUserId && {
      type: 'drafts',
      conditions: [
        {
          param: 'drafts',
          operators: [OPERATOR_TYPE_EQ],
          value: [currentUserId, '-1', '4517239960'],
        },
      ],
    },
    {
      type: 'assigned',
      conditions: [
        {
          param: 'assigned_agent',
          operators: [OPERATOR_TYPE_NOT_EQ],
          value: [null],
        },
        {
          param: 'status',
          operators: [OPERATOR_TYPE_LTEQ, OPERATOR_TYPE_EQ],
          value: ['opened'],
        },
      ],
    },
    {
      type: 'spam',
      conditions: [
        {
          param: 'status',
          operators: [OPERATOR_TYPE_EQ],
          value: ['spam'],
        },
      ],
    },
    {
      type: 'trash',
      conditions: [
        {
          param: 'deleted',
          operators: [OPERATOR_TYPE_EQ],
          value: ['true'],
        },
      ],
    },
    currentUserId && {
      type: 'snoozed',
      conditions: [
        {
          param: 'snooze_state',
          operators: [OPERATOR_TYPE_EQ],
          value: [currentUserId, '-1', '4517239960'],
        },
      ],
    },
    {
      type: 'open',
      conditions: [
        {
          param: 'status',
          operators: [OPERATOR_TYPE_EQ, OPERATOR_TYPE_LTEQ],
          value: ['opened'],
        },
      ],
    },
  ].filter(f => !!f)
}
