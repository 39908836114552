import {
  agentNameByAgentGauge,
  teamNamesByAgentGauge,
  happinessScoreRatioByAgentGauge,
  totalConversationsAssignedByAgentGauge,
  totalConversationsResolvedByAgentGauge,
} from 'ducks/reports/gauges'

import { TableV2Visualization } from 'ducks/reports/visualizations'

const tableProps = {
  join: {
    through: 'agent_name_by_agent',
  },
  gauges: [
    { ...agentNameByAgentGauge, title: app.t('Agent'), scale: { y: 'agent' } },
    { ...teamNamesByAgentGauge, title: app.t('Team'), scale: { y: 'teams' } },
    { ...totalConversationsAssignedByAgentGauge, title: 'Conversations' },
    { ...totalConversationsResolvedByAgentGauge, title: 'Resolutions' },
    { ...happinessScoreRatioByAgentGauge, title: 'CSAT Score' },
  ],
  pagination: { perPage: 10 },
  sort: {
    by: 'happiness_by_agent',
  },
}

export default function AgentsOverviewTable(props) {
  return <TableV2Visualization {...props} {...tableProps} />
}
