import { selectQueryByQueryId } from 'ducks/searches/selectors'
import { createBasicSelector } from 'util/redux'
import { selectCurrentFolderById } from '.'

export const selectFolderSortOrderContextIdByQueryId = createBasicSelector(
  (state, queryId) => {
    const query = selectQueryByQueryId(state, queryId)

    const folder = selectCurrentFolderById(state, query.folder)

    return folder?.defaultSortOrderKey
  }
)
