import { contacts, customFieldValueSubject } from 'ducks/entities/schema'

export const contactsNormalizationSchema = {
  contacts: {
    nodes: [contacts],
  },
}

export const conversationContactNormalizationSchema = {
  conversation: {
    contact: contacts,
  },
}

export const customFieldValueUpdateNormalizationScheme = {
  customFieldValuesUpdate: {
    subject: customFieldValueSubject,
  },
}

export const changeContactMutationSchema = {
  conversationChangeContact: {
    conversation: {
      contact: contacts,
    },
  },
}

export const createContactMutationSchema = {
  contactCreate: {
    contact: contacts,
  },
}

export const resyncContactMutationSchema = {
  contactResync: {
    contact: contacts,
  },
}
