import { useSelector } from 'react-redux'
import { selectContactById } from './selectors'

export const useContact = contactId => {
  const contact = useSelector(state => selectContactById(state, contactId))

  // Right now the way we're constructing the GQLV2 queries the contact will always be
  // sufficiantly loaded for the code using this hook. In future we might want to differentiate
  // between not loaded, partially loaded and fully loaded. I'm leaving this boiler plate here
  // so that we can start using this hook and in future add the functionality here.
  return {
    isLoading: false,
    isLoaded: true,
    contact,
  }
}
