import createCachedSelector from 're-reselect'
import { selectIsInboxBootstrapped } from 'selectors/app/base'
import { isEmpty } from 'util/arrays'
import { toDate } from 'util/date'
import { selectKnownMailboxes } from './selectKnownMailboxes'
import { mailboxesByStatesCacheKey } from './mailboxesByStatesCacheKey'

export const selectMailboxByStates = createCachedSelector(
  selectIsInboxBootstrapped,
  selectKnownMailboxes,
  (_state, mailboxStates) => mailboxStates,
  (_state, _mailboxStates, createdAfter) => createdAfter,
  (_state, _mailboxStates, _createdAfter, channelTypes) => channelTypes,
  (
    isInboxBootstrapped,
    mailboxes,
    mailboxStates,
    createdAfter,
    channelTypes
  ) => {
    if (!isInboxBootstrapped || !mailboxes || !mailboxStates) {
      return []
    }

    if (isEmpty(mailboxes)) return []

    return mailboxes.filter(m => {
      if (createdAfter && toDate(m.created_at) < createdAfter) {
        return false
      }

      if (channelTypes && !channelTypes.includes(m.type)) {
        return false
      }

      return mailboxStates.includes(m.state)
    })
  }
)(mailboxesByStatesCacheKey)
