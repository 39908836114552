import { createSelector } from 'reselect'
import { sortByKey } from 'util/arrays/sorting'
import { emptyObj } from 'util/objects'
import createCachedSelector from 're-reselect'
import selectBase from './selectBase'

import { selectGroups } from './groups'
import { selectItems } from './items'

export const selectFolderCollectionsById = createSelector(
  selectBase,
  base => base.collectionsById
)

export const selectFolderCollections = createSelector(
  selectFolderCollectionsById,
  collectionsById => sortByKey(Object.values(collectionsById), 'position')
)

export const selectFolderCollectionsByType = createCachedSelector(
  selectFolderCollections,
  (_state, type) => type,
  (collectionsById, type) => collectionsById.filter(c => c.type === type)
)((_state, type) => type || 'unknown')

export const selectOpenCollectionIds = createSelector(
  selectBase,
  base => base.openCollectionIds
)

const selectCollectionIdFromParam = (_state, collectionId) => collectionId

export const selectCollectionById = createCachedSelector(
  selectFolderCollectionsById,
  selectCollectionIdFromParam,
  (collectionsById, collectionId) => collectionsById[collectionId] || emptyObj
)(selectCollectionIdFromParam)

export const selectItemAndGroupIds = createCachedSelector(
  selectCollectionIdFromParam,
  selectGroups,
  selectItems,
  (collectionId, groups, items) => {
    const collectionFilter = item =>
      item.collectionId === collectionId && !item.groupId
    const typeMap = type => item => ({ ...item, type })

    return sortByKey(
      [
        ...groups.filter(collectionFilter).map(typeMap('group')),
        ...items.filter(collectionFilter).map(typeMap('item')),
      ],
      'position'
    ).map(({ id, type }) => ({ id, type }))
  }
)(selectCollectionIdFromParam)
