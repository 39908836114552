import { buildFolderKindsTypes } from './buildFolderKindsTypes'

export function getFolderKind(folder, data) {
  return (
    buildFolderKindsTypes(data).find(folderType => {
      if (folder.conditions.length !== folderType.conditions.length) return null
      let isMatch = true
      folderType.conditions.some(folderTypeCondition => {
        isMatch = folder.conditions.some(folderCondition => {
          return (
            folderTypeCondition.param === folderCondition.param.toLowerCase() &&
            folderTypeCondition.value.includes(folderCondition.value) &&
            folderTypeCondition.operators.includes(
              folderCondition.operator.toLowerCase()
            )
          )
        })
        return !isMatch
      })
      return isMatch ? folderType : null
    })?.type || null
  )
}
