import { FETCH_CONVERSATION_SUCCESS } from 'ducks/tickets/actionTypes'
import { buildConversationEventGroupsRequestKey } from 'ducks/tickets/utils/request'
import { withPush } from 'util/arrays'
import { getRawId } from 'util/globalId'
import { createActionTypeReducer } from 'util/reducers'
import { camelize } from 'util/strings'

const initialState = {
  last100RequestIds: [],
}

const updateLast100RequestIds = (draftState, requestId) => {
  if (!requestId) return
  withPush(draftState.last100RequestIds, requestId)
  if (draftState.last100RequestIds.length > 100) {
    draftState.last100RequestIds.shift()
  }
}

export default createActionTypeReducer(
  {
    '*': (
      draftState,
      { type, meta: { requestId, requestKey } = {}, payload }
    ) => {
      const actionName = camelize(type.substring(0, type.lastIndexOf('_')))
      const actionType = type.split('_').pop()
      const id = requestKey || actionName

      // Apply logic based on actionType, similar to switch-case in the original reducer
      switch (actionType) {
        case 'STARTED':
        case 'REQUEST':
          Object.assign(draftState, {
            [id]: {
              untried: false,
              loading: true,
              loaded: draftState[id]?.loaded || false,
              error: false,
            },
          })
          updateLast100RequestIds(draftState, requestId)
          break
        case 'SUCCESS':
          Object.assign(draftState, {
            [id]: {
              untried: false,
              loading: false,
              loaded: true,
              error: false,
            },
          })
          updateLast100RequestIds(draftState, requestId)
          break
        case 'FAILED':
          Object.assign(draftState, {
            [id]: {
              untried: false,
              loading: false,
              loaded: draftState[id]?.loaded || false,
              error: payload,
            },
          })
          updateLast100RequestIds(draftState, requestId)
          break
        default:
        // Do nothing for unknown action types
      }
      return draftState
    },
    [FETCH_CONVERSATION_SUCCESS]: (draftState, action) => {
      const ticketId = getRawId(action.meta?.requestParameters?.conversationId)
      if (!ticketId) return draftState

      const eventGroupRequestType = buildConversationEventGroupsRequestKey(
        ticketId
      )

      const id = camelize(eventGroupRequestType)
      if (!draftState[id]) return draftState
      draftState[id].untried = true

      return draftState
    },
  },
  initialState
)
