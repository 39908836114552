import { MAIN_PAGE, SEARCH_PAGE } from 'constants/pages'
import { isLegacyQuery } from 'ducks/searches/utils/query'
import { createSelector } from 'reselect'
import { selectCurrentPage, selectQueryParams } from 'selectors/location'
import { createBasicSelector } from 'util/redux'

export const selectIsOnSearchPageType = createSelector(
  selectCurrentPage,
  pageType => pageType === SEARCH_PAGE
)

export const selectIsOnMainPage = createBasicSelector(
  selectCurrentPage,
  pageType => pageType === MAIN_PAGE
)

export const selectIsOnSearchPageWithLegacySearch = createSelector(
  selectIsOnSearchPageType,
  selectQueryParams,
  (isSearch, query) => isSearch && isLegacyQuery(query)
)
