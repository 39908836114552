import { v4 as uuidV4 } from 'uuid'

import graphql, { mapErrorsToFormFields } from 'api/graphql'
import appGraphql from 'api/app_graphql'
import grooveAPI from 'api/groove'
import { oauthTokenSelector } from 'selectors/app'
import debug from 'util/debug'
import storage from 'util/storage'
import { getTabId } from 'util/tabId'
import { getVersion } from 'util/version'
import { isPromise, sleep } from 'util/functions'
import config from 'config'
import integrationApi from 'ducks/integrations/api'
import { manageConcurrency } from './concurrencyManager'
import { DEFAULT_MODULES } from './modules'

function transformData(data, store, options) {
  return options.transformResponse
    ? options.transformResponse(data, store, options)
    : data
}

function transformError(error, store, options) {
  return options.transformError
    ? options.transformError(error, store, options)
    : error
}

async function executeRequest(requestFn, store, variables, options) {
  const { dispatch, getState } = store
  const { cacheKey } = options
  const isCaching =
    cacheKey && config.isDevelopment && storage.get('cacheRequests')
  const lsKey = `requestCache-${options.cacheKey}`
  if (isCaching) {
    const cachedData = storage.get(lsKey, undefined)
    if (cachedData !== undefined) {
      return cachedData
    }
  }

  if (options.shesDeadJim) {
    await sleep(5000)
    throw new Error('Shes dead jim')
  }

  const data = await requestFn({
    variables,
    dispatch,
    getState,
  })

  if (isCaching) storage.set(lsKey, data)
  return data
}

function createRequestAction(phase, payload, options) {
  const action = {}
  const modules = options.modules || DEFAULT_MODULES
  modules.forEach(moduleFn => moduleFn(phase, action, payload, options))
  return action
}

export const doRequest = (
  requestType,
  promiseFunction,
  variables,
  options = {}
) => {
  return async (dispatch, getState) => {
    const { transformVariables, onBeforeSuccessAction, throwOnError } = options
    const requestId = options.requestId || uuidV4()
    const concurrencyKey = options?.concurrency?.key
    let requestParameters = variables
    if (!options.skipStarted) {
      dispatch(
        createRequestAction('STARTED', variables, {
          ...options,
          requestType,
          requestParameters,
          requestId,
        })
      )
    }
    let successAction
    if (transformVariables) {
      requestParameters = transformVariables(requestParameters)
    }

    const request = async dispatchOrQueue => {
      const store = { dispatch, getState }
      try {
        const data = await executeRequest(
          promiseFunction,
          store,
          requestParameters,
          options
        )

        let transformedData = transformData(data, store, options)
        if (isPromise(transformedData)) {
          transformedData = await transformedData
        }
        transformedData = transformedData || {}

        successAction = createRequestAction('SUCCESS', transformedData, {
          ...options,
          requestType,
          requestParameters,
          requestId,
        })
        if (onBeforeSuccessAction) {
          await onBeforeSuccessAction(transformedData)
        }

        dispatchOrQueue(successAction)

        return transformedData
      } catch (error) {
        const transformedError = transformError(error, store, options) || {}
        // eslint-disable-next-line no-console
        console.error(error)
        debug(`${options.logPrefix || 'REQUEST'}: ${requestType}_FAILED`, error)
        dispatchOrQueue(
          createRequestAction('FAILED', transformedError, {
            ...options,
            requestType,
            requestParameters,
            requestId,
            successAction,
          })
        )

        // HACK (jscheel): We have a ton of legacy code that does not listen for
        // this error. However, it is useful if you need to chain a promise catch.
        // This way, you can use promise.catch if you need to by passing this in.
        if (throwOnError) {
          throw error
        }
        return null
      }
    }

    const executionRequest = concurrencyKey
      ? manageConcurrency(
          concurrencyKey,
          dispatch,
          request,
          options.concurrency
        )
      : request(dispatch)

    return executionRequest
  }
}

export const doGraphqlRequest = (
  requestType,
  queryOrMutation,
  variables,
  options = {}
) => {
  const { throwOnError = false, throwGraphQlValidationError = false } = options
  return doRequest(
    requestType,
    async ({ variables: transformedVariables, getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)
      const graphqlFunc =
        options.graphqlFunc || (options.app === true ? appGraphql : graphql)
      try {
        const res = await graphqlFunc(
          token,
          queryOrMutation,
          transformedVariables
        )
        return res.json.data
      } catch (error) {
        if (throwOnError) {
          throw error
        } else if (throwGraphQlValidationError) {
          mapErrorsToFormFields(error)
        }
        return null
      }
    },
    variables,
    {
      ...options,
      logPrefix: 'GQL',
      throwOnError: true,
    }
  )
}

export const doIntegrationsReadRequest = (
  requestType,
  path,
  variables,
  options = {}
) => {
  return doRequest(
    requestType,
    async ({ variables: transformedVariables, getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)
      const res = await integrationApi.get(token, path, transformedVariables)
      return res.json
    },
    variables,
    {
      ...options,
      logPrefix: 'INTEGRATIONS',
    }
  )
}

export const doIntegrationsWriteRequest = (
  requestType,
  path,
  variables,
  options = {}
) => {
  const method = options.method || 'post'
  return doRequest(
    requestType,
    async ({ variables: transformedVariables, getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { json } = await integrationApi.send(
        method,
        token,
        path,
        options.params,
        JSON.stringify(transformedVariables)
      )
      return json
    },
    variables,
    {
      ...options,
      logPrefix: 'INTEGRATIONS',
    }
  )
}

export function doAppGraphqlRequest(
  requestType,
  queryOrMutation,
  variables,
  options
) {
  return doGraphqlRequest(requestType, queryOrMutation, variables, {
    ...options,
    app: true,
  })
}

export const doSdkRequest = (
  requestType,
  sdkPromiseFunction,
  variables = {},
  options = {}
) => {
  return doRequest(requestType, sdkPromiseFunction, variables, {
    ...options,
    logPrefix: 'SDK',
  })
}

export const doApiReadRequest = (
  requestType,
  path,
  variables,
  options = {}
) => {
  return doRequest(
    requestType,
    async ({ variables: transformedVariables, getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)
      const { json, success } = await grooveAPI.get(
        token,
        path,
        transformedVariables
      )

      if (success === false) {
        throw json.errors
      }

      return json
    },
    variables,
    {
      ...options,
      logPrefix: 'API',
    }
  )
}

export const doApiPutRequest = (requestType, path, variables, options = {}) => {
  return doRequest(
    requestType,
    async ({ variables: transformedVariables, getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { json, success } = await grooveAPI.put(
        token,
        path,
        options.params,
        JSON.stringify(transformedVariables)
      )

      if (success === false) {
        throw json.errors
      }

      return json
    },
    variables,
    options
  )
}

export const doApiWriteRequest = (
  requestType,
  path,
  variables,
  options = {}
) => {
  const method = options.method || 'post'
  return doRequest(
    requestType,
    async ({ variables: transformedVariables, getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { json, success } = await grooveAPI.send(
        method,
        token,
        path,
        options.params,
        JSON.stringify(transformedVariables)
      )

      if (success === false) {
        throw json.errors
      }

      return json
    },
    variables,
    options
  )
}

export function doAttachementRequest(
  requestType,
  path,
  attachments,
  options = {}
) {
  return doRequest(
    requestType,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)
      const formData = new FormData()
      attachments.forEach(attachment => formData.append('files[]', attachment))
      return fetch(`${config.uploaderUrl}${path}`, {
        body: formData,
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Request-Id': uuidV4(),
          'X-Client-Tab-Id': getTabId(),
          'X-Client-App-Version': getVersion(),
        },
        method: 'POST',
      })
    },
    {},
    options
  )
}
