import {
  selectCurrentEntitiesById,
  selectPendingEntityById,
  selectCurrentEntityById,
  selectCurrentEntities,
} from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import { emptyArr } from 'util/arrays'
import { FETCH_FOLDERS_BY_IDS_V2 } from '../../actionTypes/folders'

export const selectCurrentFoldersById = state =>
  selectCurrentEntitiesById(state, 'folder') || null

export const selectCurrentFolders = state =>
  selectCurrentEntities(state, 'folder') || emptyArr

export const selectCurrentFolderById = (state, id) =>
  selectCurrentEntityById(state, 'folder', id) || null

export const selectPendingFolderById = (state, id) =>
  selectPendingEntityById(state, 'folder', id) || null

export const selectIsLoadingFolderById = state =>
  selectRequestByType(state, FETCH_FOLDERS_BY_IDS_V2).loading

export const selectHasLoadedFolderById = state =>
  selectRequestByType(state, FETCH_FOLDERS_BY_IDS_V2).loaded

export const selectHasErrorFolderById = state =>
  selectRequestByType(state, FETCH_FOLDERS_BY_IDS_V2).error
