import React from 'react'

import { Overview16 } from 'ducks/reports/overviewLayouts'

import {
  ConversationsResolvedPerDayHeadline,
  TotalConversationsResolvedHeadline,
} from 'ducks/reports/conversations'

import ConversationsAssignedPerDayHeadline from './ConversationsAssignedPerDayHeadline'
import CustomersAssignedPerDayHeadline from './CustomersAssignedPerDayHeadline'
import AssignmentsAssignmentCustomersResolutionsForEachDay from './AssignmentsAssignmentCustomersResolutionsForEachDay'
import TotalConversationsAssignedHeadline from './TotalConversationsAssignedHeadline'
import TotalCustomersAssignedHeadline from './TotalCustomersAssignedHeadline'

export default function ConversationsAssignedOverview({
  queryBase,
  netDiffComponent,
  resolutionsQueryBase,
}) {
  return (
    <Overview16
      title="Conversations"
      components={[
        <AssignmentsAssignmentCustomersResolutionsForEachDay
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
        />,
        netDiffComponent,
        null,
        <TotalConversationsAssignedHeadline
          includePrevious
          queryBase={queryBase}
        />,
        <ConversationsAssignedPerDayHeadline
          includePrevious
          queryBase={queryBase}
        />,
        <TotalConversationsResolvedHeadline
          includePrevious
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
        />,
        <ConversationsResolvedPerDayHeadline
          includePrevious
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
        />,
        <TotalCustomersAssignedHeadline
          includePrevious
          queryBase={queryBase}
        />,
        <CustomersAssignedPerDayHeadline
          includePrevious
          queryBase={queryBase}
        />,
      ]}
    />
  )
}
