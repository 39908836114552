export const findAddedReactionAction = (
  computedReactions,
  commentId,
  actorId,
  reaction
) => {
  const reactionsForComment = computedReactions[commentId]

  if (!reactionsForComment) {
    return null
  }

  const reactionActions = reactionsForComment[reaction]

  if (!reactionActions) {
    return null
  }

  return reactionActions.find(a => a.change.is_added && a.actor.id === actorId)
}
