import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import {
  selectCurrentCompany,
  selectCurrentCompanyId,
} from 'ducks/crm/companies/selectors/current'
import {
  selectCurrentContact,
  selectCurrentContactId,
} from 'ducks/crm/contacts/selectors/current'
import { selectCurrentTicketGlobalId } from 'ducks/tickets/selectors/selectCurrentTicketGlobalId'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'
import {
  CUSTOM_FIELD_CATEGORY_TYPE_CHANNEL,
  CUSTOM_FIELD_CATEGORY_TYPE_COMPANY,
} from '../../constants'

export const selectSubjectIdForCategoryType = createSelector(
  selectCurrentCompanyId,
  selectCurrentContactId,
  selectCurrentTicketGlobalId,
  (_state, { type }) => type,
  (companyId, contactId, channelId, type) => {
    if (type === CUSTOM_FIELD_CATEGORY_TYPE_CHANNEL) return channelId
    if (type === CUSTOM_FIELD_CATEGORY_TYPE_COMPANY) return companyId
    return contactId
  }
)

export const selectSubjectForCategoryType = createCachedSelector(
  selectCurrentCompany,
  selectCurrentContact,
  selectCurrentConversation,
  (_state, { categoryType }) => categoryType,
  (company, contact, channel, type) => {
    if (type === CUSTOM_FIELD_CATEGORY_TYPE_CHANNEL) return channel
    if (type === CUSTOM_FIELD_CATEGORY_TYPE_COMPANY) return company
    return contact
  }
)((state, { categoryType }) => categoryType)
