export const MAILBOX_CHANNEL_TYPE = 'mailbox'
export const SOCIAL_CHANNEL_TYPE = 'social'
export const WIDGET_CHANNEL_TYPE = 'widget'
export const MAILBOX_GQL_TYPE = 'Channel'
export const WIDGET_GQL_TYPE = 'Widget'
export const AGENT_GROUP_ID = 'fg_agents'
export const LABEL_GROUP_ID = 'fg_labels'

export const GROUPS = [
  {
    id: AGENT_GROUP_ID,
    name: 'Agents',
    icon: 'usersAlt',
  },
  {
    id: LABEL_GROUP_ID,
    name: 'Labels',
    icon: 'tag',
  },
]

export const DEFAULT_OPEN_SECTIONS = { Starred: true, Folders: true }

export const OPEN_SECTIONS_KEY = 'inbox-leftnav-open-sections'

export const FOLDER_KIND_DRAFT = 'drafts'

export const FOLDER_TYPE = Object.freeze({
  TICKET: 'TICKET',
  CHAT: 'CHAT',
  ALL: 'ALL',
})

export const FOLDER_STATE = Object.freeze({
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
})

export const FOLDER_CONDITION_TYPES = Object.freeze({
  STARRED: 'STARRED',
  PRIORITY: 'PRIORITY',
  STATUS: 'STATUS',
  DELETED: 'DELETED',
  CHANNEL: 'CHANNEL',
  SNOOZE_UNTIL: 'SNOOZE_UNTIL',
  SNOOZE_STATE: 'SNOOZE_STATE',
  ASSIGNED_AGENT: 'ASSIGNED_AGENT',
  ASSIGNED_GROUP: 'ASSIGNED_GROUP',
  INTERACTION_COUNT: 'INTERACTION_COUNT',
  HOURS_SINCE_UPDATED: 'HOURS_SINCE_UPDATED',
  HOURS_SINCE_STATUS_CHANGED: 'HOURS_SINCE_STATUS_CHANGED',
  HOURS_SINCE_OPEN: 'HOURS_SINCE_OPEN',
  HOURS_SINCE_PENDING: 'HOURS_SINCE_PENDING',
  HOURS_SINCE_CLOSED: 'HOURS_SINCE_CLOSED',
  HOURS_SINCE_ASSIGNED: 'HOURS_SINCE_ASSIGNED',
  HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE:
    'HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE',
  LABELS: 'LABELS',
})
