export const FETCH_CUSTOM_FIELDS = 'crm/customFields/FETCH_CUSTOM_FIELDS'
export const FETCH_CUSTOM_FIELDS_STARTED =
  'crm/customFields/FETCH_CUSTOM_FIELDS_STARTED'
export const FETCH_CUSTOM_FIELDS_SUCCESS =
  'crm/customFields/FETCH_CUSTOM_FIELDS_SUCCESS'
export const FETCH_CUSTOM_FIELDS_FAILED =
  'crm/customFields/FETCH_CUSTOM_FIELDS_FAILED'

export const FETCH_ALL_CUSTOM_FIELDS_STARTED =
  'crm/customFields/FETCH_ALL_CUSTOM_FIELDS_STARTED'
export const FETCH_ALL_CUSTOM_FIELDS_SUCCESS =
  'crm/customFields/FETCH_ALL_CUSTOM_FIELDS_SUCCESS'
export const FETCH_ALL_CUSTOM_FIELDS_FAILED =
  'crm/customFields/FETCH_ALL_CUSTOM_FIELDS_FAILED'

export const CREATE_CUSTOM_FIELD = 'crm/customFields/CREATE_CUSTOM_FIELD'
export const CREATE_CUSTOM_FIELD_STARTED =
  'crm/customFields/CREATE_CUSTOM_FIELD_STARTED'
export const CREATE_CUSTOM_FIELD_SUCCESS =
  'crm/customFields/CREATE_CUSTOM_FIELD_SUCCESS'
export const CREATE_CUSTOM_FIELD_FAILED =
  'crm/customFields/CREATE_CUSTOM_FIELD_FAILED'

export const UPDATE_CUSTOM_FIELD = 'crm/customFields/UPDATE_CUSTOM_FIELD'
export const UPDATE_CUSTOM_FIELD_STARTED =
  'crm/customFields/UPDATE_CUSTOM_FIELD_STARTED'
export const UPDATE_CUSTOM_FIELD_SUCCESS =
  'crm/customFields/UPDATE_CUSTOM_FIELD_SUCCESS'
export const UPDATE_CUSTOM_FIELD_FAILED =
  'crm/customFields/UPDATE_CUSTOM_FIELD_FAILED'

export const TRASH_CUSTOM_FIELD = 'crm/customFields/TRASH_CUSTOM_FIELD'
export const TRASH_CUSTOM_FIELD_STARTED =
  'crm/customFields/TRASH_CUSTOM_FIELD_STARTED'
export const TRASH_CUSTOM_FIELD_SUCCESS =
  'crm/customFields/TRASH_CUSTOM_FIELD_SUCCESS'
export const TRASH_CUSTOM_FIELD_FAILED =
  'crm/customFields/TRASH_CUSTOM_FIELD_FAILED'

export const FETCH_ALL_TRASHED_CUSTOM_FIELDS_STARTED =
  'crm/customFields/FETCH_ALL_TRASHED_CUSTOM_FIELDS_STARTED'
export const FETCH_ALL_TRASHED_CUSTOM_FIELDS_SUCCESS =
  'crm/customFields/FETCH_ALL_TRASHED_CUSTOM_FIELDS_SUCCESS'
export const FETCH_TRASHED_CUSTOM_FIELDS =
  'crm/customFields/FETCH_TRASHED_CUSTOM_FIELDS'

export const ADDRESS = 'ADDRESS'
export const DATE = 'DATE'
export const DROPDOWN = 'DROPDOWN'
export const MULTI_SELECT = 'MULTI_SELECT'
export const DURATION = 'DURATION'
export const IP_LOCATION = 'IP_LOCATION'
export const LINK = 'LINK'
export const MULTI_LINE = 'MULTI_LINE'
export const NUMBER = 'NUMBER'
export const SINGLE_LINE = 'SINGLE_LINE'
export const SOURCE_AND_MEDIUM = 'SOURCE_AND_MEDIUM'
export const TIMEZONE = 'TIMEZONE'
export const URL = 'URL'
export const BOOLEAN = 'BOOLEAN'

export const TYPE_ICONS_MAP = {
  ADDRESS: 'location',
  DATE: 'calendar',
  DURATION: 'stopwatch',
  DROPDOWN: 'dropdown',
  MULTI_SELECT: 'dropdown',
  FILE: 'paperclip',
  FIRST_SESSION: 'book',
  IP_ADDRESS: 'network',
  IP_LOCATION: 'location',
  LATEST_SESSION: 'book',
  LINK: 'externalLink',
  NUMBER: 'hashtag',
  MULTI_LINE: 'fileEdit',
  SINGLE_LINE: 'edit',
  SOURCE_AND_MEDIUM: 'pieChart',
  SUM: 'hashtag',
  URL: 'globe',
  USER_AGENT: 'window',
}
export const CUSTOM_FIELD_TYPES = Object.keys(TYPE_ICONS_MAP)

export const AT = 'AT'
export const AWARD = 'AWARD'
export const BOOK = 'BOOK'
export const BOOKS = 'BOOKS'
export const BUILDING = 'BUILDING'
export const CALENDAR = 'CALENDAR'
export const CLOCK = 'CLOCK'
export const COINS = 'COINS'
export const COPY = 'COPY'
export const DOLLAR = 'DOLLAR'
export const FACEBOOK = 'FACEBOOK'
export const FILE = 'FILE'
export const GIFT = 'GIFT'
export const GLOBE = 'GLOBE'
export const GRADUATION = 'GRADUATION'
export const HASHTAG = 'HASHTAG'
export const HOURGLASS = 'HOURGLASS'
export const INSTITUTION = 'INSTITUTION'
export const IMAGE = 'IMAGE'
export const LANGUAGE = 'LANGUAGE'
export const LINE_CHART = 'LINE_CHART'
export const LINKEDIN = 'LINKEDIN'
export const LOCATION = 'LOCATION'
export const NETWORK = 'NETWORK'
export const NOTEBOOK = 'NOTEBOOK'
export const PAGE = 'PAGE'
export const PEOPLE = 'PEOPLE'
export const PERSON = 'PERSON'
export const PHONE = 'PHONE'
export const PIE_CHART = 'PIE_CHART'
export const PRESENT = 'PRESENT'
export const RECEIPT = 'RECEIPT'
export const STOPWATCH = 'STOPWATCH'
export const TWITTER = 'TWITTER'
export const WINDOW = 'WINDOW'
export const PAPERCLIP = 'PAPERCLIP'
export const EXTERNAL_LINK = 'EXTERNAL_LINK'
export const EDIT = 'EDIT'
export const FILE_EDIT = 'FILE_EDIT'

export const CUSTOM_FIELD_ICONS = [
  AT,
  AWARD,
  BOOK,
  BOOKS,
  BUILDING,
  CALENDAR,
  CLOCK,
  COINS,
  COPY,
  DOLLAR,
  DROPDOWN,
  EDIT,
  EXTERNAL_LINK,
  FACEBOOK,
  FILE_EDIT,
  FILE,
  GIFT,
  GLOBE,
  GRADUATION,
  HASHTAG,
  HOURGLASS,
  IMAGE,
  INSTITUTION,
  LANGUAGE,
  LINE_CHART,
  LINKEDIN,
  LOCATION,
  NETWORK,
  NOTEBOOK,
  PAGE,
  PAPERCLIP,
  PEOPLE,
  PERSON,
  PHONE,
  PIE_CHART,
  PRESENT,
  RECEIPT,
  STOPWATCH,
  TWITTER,
  WINDOW,
]

export const PUBLIC_CUSTOM_FIELD_TYPES = [
  ADDRESS,
  DATE,
  DROPDOWN,
  MULTI_SELECT,
  LINK,
  NUMBER,
  MULTI_LINE,
  SINGLE_LINE,
]

export const COMPANY = 'COMPANY'
export const CONTACT = 'CONTACT'

export const UPLOAD_CUSTOM_FIELD_FILE =
  'crm/customFields/UPLOAD_CUSTOM_FIELD_FILE'
export const RESTORE_CUSTOM_FIELD = 'crm/customFields/RESTORE_CUSTOM_FIELD'
export const RESTORE_CUSTOM_FIELD_STARTED =
  'crm/customFields/RESTORE_CUSTOM_FIELD_STARTED'
export const RESTORE_CUSTOM_FIELD_FAILED =
  'crm/customFields/RESTORE_CUSTOM_FIELD_FAILED'
export const RESTORE_CUSTOM_FIELD_SUCCESS =
  'crm/customFields/RESTORE_CUSTOM_FIELD_SUCCESS'
