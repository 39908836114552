import React from 'react'

import { Grid } from 'util/ui'

import styles from './styles.less'

export default function Overview16({ title, components }) {
  return (
    <Grid stackable celled padded className={styles.overviewContainer}>
      <Grid.Row columns={1}>
        <h1 className={styles.reportHeader}>{title}</h1>
      </Grid.Row>
      <Grid.Row columns={1} className={`${styles.chartContainer}`}>
        {components[0]}
      </Grid.Row>
      <Grid.Row columns={4}>
        <Grid.Column width={4} className={styles.noPadding} verticalAlign="top">
          {components[1] !== null && (
            <div className={styles.Overview16_gauge}>{components[1]}</div>
          )}
          {components[2] !== null && (
            <div className={styles.Overview16_gauge}>{components[2]}</div>
          )}
        </Grid.Column>
        <Grid.Column width={4} className={styles.noPadding} verticalAlign="top">
          {components[3] !== null && (
            <div className={styles.Overview16_gauge}>{components[3]}</div>
          )}
          {components[4] !== null && (
            <div className={styles.Overview16_gauge}>{components[4]}</div>
          )}
        </Grid.Column>
        <Grid.Column width={4} className={styles.noPadding} verticalAlign="top">
          {components[5] !== null && (
            <div className={styles.Overview16_gauge}>{components[5]}</div>
          )}
          {components[6] !== null && (
            <div className={styles.Overview16_gauge}>{components[6]}</div>
          )}
        </Grid.Column>
        <Grid.Column width={4} className={styles.noPadding} verticalAlign="top">
          {components[7] !== null && (
            <div className={styles.Overview16_gauge}>{components[7]}</div>
          )}
          {components[8] !== null && (
            <div className={styles.Overview16_gauge}>{components[8]}</div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
