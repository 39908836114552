import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setKind } from 'redux-first-router'
import {
  selectCurrentPage,
  selectQueryParams,
  selectCurrentPayload,
} from 'selectors/location'
import { compact } from 'util/objects'
import { selectDefaultSortOrderByQueryId } from 'ducks/searches/selectors/selectDefaultSortOrderByQueryId'
import { queryStringToQueryId } from 'ducks/searches/utils/query'

const buildTabPageAction = ({ tabId, kind }) => (dispatch, getState) => {
  const state = getState()
  const pageType = selectCurrentPage(state)
  const pagePayload = selectCurrentPayload(state)
  const currentPageQuery = selectQueryParams(state)

  const pageAction = {
    type: pageType,
    payload: {
      ...pagePayload,
      tab: tabId,
    },
    meta: {
      query: currentPageQuery,
    },
  }

  return dispatch(kind ? setKind(pageAction, kind) : pageAction)
}

export const useTabs = ({ tabs: inputTabs, defaultTabId }) => {
  const dispatch = useDispatch()
  const pagePayload = useSelector(selectCurrentPayload)
  const { tab: urlTabId = defaultTabId } = pagePayload

  const tabs = useMemo(() => inputTabs || [], [inputTabs])
  const activeTab = useMemo(
    () => {
      const urlTab = tabs.find(t => t.key === urlTabId)
      if (urlTab) return urlTab
      const defaultTab = tabs.find(t => t.key === defaultTabId)
      if (defaultTab) return defaultTab
      if (tabs.length > 0) return tabs[0]
      return null
    },
    [urlTabId, defaultTabId, tabs]
  )

  const tabId = useMemo(
    () => {
      return activeTab?.key || urlTabId
    },
    [urlTabId, activeTab]
  )

  const onTabChange = useCallback(
    inputTabId => {
      if (inputTabId === tabId) return
      const payload = {}
      if (inputTabId) {
        payload.tab = inputTabId
      }
      dispatch(buildTabPageAction({ tabId: inputTabId }))
    },
    [dispatch, tabId]
  )

  return {
    onTabChange,
    tabs,
    activeTab,
    ActiveTabComponent: activeTab?.component,
    activeTabId: tabId,
    pagePayload,
  }
}

export const useLink = (
  inputAction,
  { preserveQuery = false, addDefaultOrderBy = false } = {}
) => {
  const currentPageQuery = useSelector(selectQueryParams)
  const currentPageType = useSelector(selectCurrentPage)
  const currentPagePayload = useSelector(selectCurrentPayload)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => inputAction, [])

  const pageAction = useMemo(
    () => {
      let newAction = action
      if (preserveQuery) {
        newAction = {
          ...action,
          type: action.type || currentPageType,
          payload: action.payload || currentPagePayload,
          meta: {
            ...(action.meta || {}),
            query: compact({
              ...currentPageQuery,
              ...(action?.meta?.query || {}),
            }),
          },
        }
      }

      return newAction
    },
    [
      action,
      currentPageQuery,
      preserveQuery,
      currentPageType,
      currentPagePayload,
    ]
  )

  const newQueryId = useMemo(
    () => {
      return queryStringToQueryId(pageAction.meta.query)
    },
    [pageAction]
  )

  const defaultOrderBy = useSelector(state =>
    selectDefaultSortOrderByQueryId(state, newQueryId)
  )

  const pageActionWithOrderBy = useMemo(
    () => {
      const pageActionWithSort = pageAction
      if (addDefaultOrderBy) {
        if (!pageActionWithSort?.meta?.query?.orderBy && defaultOrderBy) {
          if (!pageActionWithSort.meta) pageActionWithSort.meta = {}
          if (!pageActionWithSort.meta.query) pageActionWithSort.meta.query = {}
          pageActionWithSort.meta.query.orderBy = defaultOrderBy
        }
      }
      return pageActionWithSort
    },
    [defaultOrderBy, pageAction, addDefaultOrderBy]
  )

  return pageActionWithOrderBy
}
