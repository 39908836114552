import { connect } from 'react-redux'
import {
  agentNameByAgentGauge,
  happinessScoreRatioByAgentGauge,
  averageResolutionTimeByAgentGauge,
  totalConversationsAssignedByAgentGauge,
  totalConversationsResolvedByAgentGauge,
  averageResponseTimeByAgentGauge,
  averageFirstResponseTimeByAgentGauge,
} from 'ducks/reports/gauges'
import { TableV2Visualization } from 'ducks/reports/visualizations'
import { styles } from './styles'

const select = (state, initialProps) => {
  const { customerRatingsInstalled } = initialProps

  const gauges = [
    {
      ...agentNameByAgentGauge,
      title: app.t('Agent'),
      scale: { y: 'agent' },
    },
    { ...totalConversationsAssignedByAgentGauge, title: 'Conversations' },
    { ...totalConversationsResolvedByAgentGauge, title: 'Resolutions' },
    {
      ...averageResponseTimeByAgentGauge,
      title: '$Avg. Response Time',
    },
    {
      ...averageFirstResponseTimeByAgentGauge,
      title: '$Avg. First Response Time',
    },
    {
      ...averageResolutionTimeByAgentGauge,
      title: '$Avg. Resolution Time',
    },
  ]

  if (customerRatingsInstalled)
    gauges.push({ ...happinessScoreRatioByAgentGauge, title: 'CSAT Score' })

  return {
    customerRatingsInstalled,
    join: {
      through: 'agent_name_by_agent',
    },
    gauges,
    sort: {
      by: customerRatingsInstalled
        ? 'happiness_by_agent'
        : 'avg_resolution_time_by_agent',
    },
    title: `${app.t('Team')} Leaderboard`,
    pagination: { perPage: 10 },
  }
}

export default connect(select)(TeamLeaderBoardTable)

function TeamLeaderBoardTable(props) {
  return <TableV2Visualization {...props} css={styles.teamLeaderBoardTable} />
}
