import {
  selectAccessibleCurrentEmailChannels,
  selectChannelTypeFromUrl,
} from 'ducks/channels/selectors'
import { selectWidgetsWithChat } from 'ducks/widgets/selectors'
import { createSelector } from 'reselect'
import { defaultFolderItemQueryId } from 'ducks/searches/utils/query'
import { selectIsInChat } from 'selectors/location'
import { selectPrefersAllMailboxesSectionVisible } from 'ducks/currentUser/selectors/preferences/selectPrefersAllMailboxesSectionVisible'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectCurrentQueryOrderByOrDefault } from 'ducks/searches/selectors'
import { FOLDER_TYPE } from 'ducks/folders/constants'
import { selectCurrentFolders } from '../folders'

export const selectDefaultFolderItemQueryId = createSelector(
  selectWidgetsWithChat,
  selectAccessibleCurrentEmailChannels,
  selectCurrentFolders,
  selectIsInChat,
  selectChannelTypeFromUrl,
  selectPrefersAllMailboxesSectionVisible,
  selectPrefersUnifiedInbox,
  selectCurrentQueryOrderByOrDefault,
  (
    widgets,
    mailboxes,
    folders,
    isInChat,
    channelType,
    prefersAllMailboxesSectionVisible,
    prefersUnifiedInbox,
    orderBy
  ) => {
    const scopedWidgets = widgets.filter(w => w.channelType === channelType)

    const channels = isInChat ? scopedWidgets : mailboxes

    const scopedFolders = folders.filter(
      f => (f.type === isInChat ? FOLDER_TYPE.CHAT : FOLDER_TYPE.TICKET)
    )

    return defaultFolderItemQueryId({
      channels,
      folders: scopedFolders,
      prefersAllMailboxesSectionVisible,
      prefersUnifiedInbox,
      pageChannelType: channelType,
      orderBy,
    })
  }
)
