import { selectCurrentEntities } from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import createCachedSelector from 're-reselect'
import { createBasicSelector } from 'util/redux'
import { emptyArr } from 'util/arrays'
import { buildConversationAttachmentsRequestKey } from './utils'

export const selectAttachments = state =>
  selectCurrentEntities(state, 'attachment')

export const selectAttachmentsByTicketId = createBasicSelector(
  selectAttachments,
  attachments =>
    attachments.reduce((byTicketId, attachment) => {
      if (!attachment.ticketId) return byTicketId
      // eslint-disable-next-line no-param-reassign
      if (!byTicketId[attachment.ticketId]) byTicketId[attachment.ticketId] = []
      byTicketId[attachment.ticketId].push(attachment)
      return byTicketId
    }, {})
)

export const selectAttachmentsForTicketId = createCachedSelector(
  selectAttachmentsByTicketId,
  (state, ticketId) => ticketId,
  (byTicketId, ticketId) => {
    return byTicketId[ticketId] || emptyArr
  }
)((state, ticketId) => ticketId || 'unknown')

export const selectConversationAttachmentsRequestByTicketId = createBasicSelector(
  (state, ticketId) => {
    return selectRequestByType(
      state,
      buildConversationAttachmentsRequestKey(ticketId)
    )
  }
)
