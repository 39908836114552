import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import {
  selectCurrentEntities,
  selectCurrentEntitiesById,
} from 'ducks/entities/selectors'

export function selectBase(state) {
  return state.crm.contacts
}

export function selectContactIdsByIdentifyingValue(state) {
  return selectBase(state).byIdentifyingValue
}

export function selectContactsById(state) {
  return selectCurrentEntitiesById(state, 'contacts')
}

export const selectContacts = state => {
  return selectCurrentEntities(state, 'contacts')
}

// Kevin R (2024-09-04)
// Unfortunately im forced to do abit of a hack. We have the new entities duck
// which does a much better job of entity managment in general. Unfortunately
// one of the limitation of this store is that custom "states" like isCreating,
// isPartiallyLoaded etc cannot be stored there. In order to work around this
// all selectors requiring data will pull from the entities store and anything
// checking state will pull from the original store.
export function selectContactStatesById(state) {
  return selectBase(state).byId
}

export const selectContactForEmail = createCachedSelector(
  selectContacts,
  (_state, email) => email,
  (contacts, email) => contacts.find(c => c.email === email)
)((_state, email) => email || 'unknown')

export const selectContactById = createCachedSelector(
  selectContactsById,
  (_state, contactId) => contactId,
  (contactsById, contactId) => contactsById[contactId]
)((_state, contactId) => contactId || 'unknown')

export function selectLatestLoadedRequestKey(state) {
  return selectBase(state).latestLoadedRequestKey
}

export function selectLatestLoadedSearchTerm(state) {
  return selectBase(state).latestLoadedSearchTerm
}

export function selectLatestRequestKey(state) {
  return selectBase(state).latestRequestKey
}

export function selectRequestsByKey(state) {
  return selectBase(state).byRequestKey
}

export function selectPageCursor(state) {
  return selectBase(state).cursor
}

export function selectTotalContactsCount(state) {
  return selectBase(state).totalCount
}

export function selectContactIdsByCustomerId(state) {
  return selectBase(state).contactIdsByCustomerId
}

const selectResyncContact = createBasicSelector(
  selectBase,
  base => base.resyncContact || emptyObj
)

const selectResyncContactByIdAndIntegrationId = createBasicSelector(
  selectResyncContact,
  resyncContact => resyncContact.byIdAndIntegrationId || emptyObj
)

export const selectResyncContactForIdAndIntegrationId = createCachedSelector(
  selectResyncContactByIdAndIntegrationId,
  (_state, integrationId) => integrationId,
  (_state, _integrationId, contactId) => contactId,
  (byIdAndIntegrationId, integrationId, contactId) => {
    return byIdAndIntegrationId[`${contactId}-${integrationId}`] || emptyObj
  }
)(
  (state, integrationId, contactId) =>
    `${integrationId || 'unknown'}-${contactId || 'unknown'}`
)
