import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isBoostrappedSelector } from 'selectors/app'

import {
  doBootstrapCustomFieldsAndCategories,
  doFetchAllTrashedCustomFields,
} from './operations/fetching'
import { CONTACT, COMPANY } from './types'
import {
  selectIsTrashedCustomFieldsLoading,
  selectIsTrashedCustomFieldsErrored,
  selectIsTrashedCustomFieldsForType,
} from './selectors/trashed'

import {
  selectAllCustomFieldsIsLoaded,
  selectAllCustomFieldsIsLoading,
  selectCustomFieldForId,
  selectCustomFieldForKey,
  selectCustomFields,
  selectIsCustomFieldsOrCategoriesLoading,
} from './selectors'
import {
  selectAllCustomFieldCategoriesIsLoaded,
  selectAllCustomFieldCategoriesIsLoading,
  selectCustomFieldCategories,
} from '../customFieldCategories'

export function useTrashedCustomFields() {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsTrashedCustomFieldsLoading)
  const isErrored = useSelector(selectIsTrashedCustomFieldsErrored)
  useEffect(() => {
    if (!isLoading) dispatch(doFetchAllTrashedCustomFields())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    isLoading,
    isErrored,
    contactFields: useSelector(state =>
      selectIsTrashedCustomFieldsForType(state, { type: CONTACT })
    ),
    companyFields: useSelector(state =>
      selectIsTrashedCustomFieldsForType(state, { type: COMPANY })
    ),
  }
}

export const useCustomField = (identifier, { mode = 'id' }) => {
  const isLoading = useSelector(selectIsCustomFieldsOrCategoriesLoading)

  const isBootstrapped = useSelector(isBoostrappedSelector)

  const loading = isLoading || !isBootstrapped

  const customField = useSelector(state => {
    if (mode === 'id') {
      return selectCustomFieldForId(state, { id: identifier })
    }
    return selectCustomFieldForKey(state, { key: identifier })
  })

  return {
    customField: loading ? null : customField,
    isLoading: loading,
    isMissing: !customField && !loading,
  }
}

export const useLoadAllCustomFields = () => {
  const dispatch = useDispatch()
  const isCustomFieldsLoading = useSelector(selectAllCustomFieldsIsLoading)
  const isCustomFieldCategoriesLoading = useSelector(
    selectAllCustomFieldCategoriesIsLoading
  )
  const isCustomFieldsLoaded = useSelector(selectAllCustomFieldsIsLoaded)
  const isCustomFieldCategoriesLoaded = useSelector(
    selectAllCustomFieldCategoriesIsLoaded
  )

  const customFields = useSelector(selectCustomFields)
  const customFieldCategories = useSelector(selectCustomFieldCategories)

  const isLoading = isCustomFieldsLoading || isCustomFieldCategoriesLoading
  const isLoaded = isCustomFieldsLoaded && isCustomFieldCategoriesLoaded

  const reload = useCallback(
    () => dispatch(doBootstrapCustomFieldsAndCategories()),
    [dispatch]
  )

  useEffect(
    () => {
      if (!isLoading && !isLoaded) {
        reload()
      }
    },
    [reload, isLoading, isLoaded]
  )

  return {
    isLoading,
    isLoaded,
    isCustomFieldsLoading,
    isCustomFieldCategoriesLoading,
    isCustomFieldsLoaded,
    isCustomFieldCategoriesLoaded,
    customFields,
    customFieldCategories,
    reload,
  }
}
