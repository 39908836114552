import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { camelize } from 'util/strings'

const DEFAULT_REQUEST = {
  untried: true,
  loading: false,
  loaded: false,
  error: false,
}

export const selectBase = createBasicSelector(state => state.request)

export const selectRequestsByType = createBasicSelector(
  selectBase,
  base => base || emptyObj
)

export const makeSelectRequest = slice => state => {
  return selectBase(state)[slice] || DEFAULT_REQUEST
}

export const selectRequestByType = createCachedSelector(
  selectBase,
  (_state, requestType) => camelize(requestType),
  (request, requestType) => request[requestType] || DEFAULT_REQUEST
)((_state, requestType) => requestType)

export const selectLast100RequestIds = createBasicSelector(
  selectBase,
  base => base.last100RequestIds
)
