import { selectQueryParams } from 'selectors/location'
import { setKind } from 'redux-first-router'

export const goToPage = (pageAction, { kind, preserveQuery } = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const currentPageQuery = selectQueryParams(state)

  const action = { ...pageAction }

  if (preserveQuery) {
    action.meta = {
      ...(action.meta || {}),
      query: { ...(action?.meta?.query || {}), ...currentPageQuery },
    }
  }

  return dispatch(kind ? setKind(action, kind) : action)
}
