import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import emailParser from 'email-addresses'
import {
  firstEnduserActorFromDenormalizedEventGroup,
  getUniqueRecipients,
} from 'ducks/drafts2/util'
import { selectPreferredMailboxId } from 'ducks/currentUser/selectors/preferences/selectPreferredMailboxId'

import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectMailboxIdFromUrl } from 'ducks/mailboxes/selectors/selectMailboxIdFromUrl'
import { selectFirstMailboxId } from 'ducks/mailboxes/selectors/selectFirstMailboxId'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import {
  selectIsAddingNote,
  selectIsLoggingNewConversation,
} from 'selectors/page'

import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import { extractTwitterHandles, stripHTML } from 'util/strings'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectConversationEventGroupsByConversationId } from 'ducks/tickets/selectors/selectConversationEventGroupsByConversationId'
import { selectCurrentUserPreferReassignTicketOnReply } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPreferReassignTicketOnReply'
import { CLOSED, OPENED } from 'ducks/tickets/constants'
import { queryParams } from 'util/params'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { selectCurrentUserPreferReassignTicketOnNote } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPreferReassignTicketOnNote'
import { selectAccountPrefersReplyOpen } from 'selectors/app/selectAccountPreferences'
import { selectCurrentAgentsById } from 'selectors/agents/base'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'
import { assignmentLabel } from 'util/assignment'
import { twitterHandleFromContact } from 'ducks/customers/utils'
import { getAccountTwitterUsername } from 'util/ticket'
import { isFacebook, isTwitter } from 'ducks/tickets/utils/type'

const storeKey = 'drafts2'

export const selectById = state => state[storeKey].byId || emptyObj

export const selectRawDraftsByTicketId = state => {
  return state[storeKey]?.byTicketId || emptyObj
}

export const selectByMessageId = state =>
  state[storeKey].byMessageId || emptyObj

export const selectDraftIdByTicketId = createCachedSelector(
  selectRawDraftsByTicketId,
  (_state, ticketId) => ticketId,
  (_state, _ticketId, type) => type,
  (byTicketId, ticketId, type) => {
    const draftIdSet = byTicketId[ticketId]
    return draftIdSet ? draftIdSet[type] : null
  }
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftById = createCachedSelector(
  selectById,
  (_state, draftId) => draftId,
  (draftById, draftId) => draftById[draftId] || null
)((_state, draftId) => draftId || 'unknown')

export const selectDraftBodyByDraftId = (state, draftId) => {
  const found = selectDraftById(state, draftId)
  return found ? found.body : null
}

export const selectAttachmentsByDraftId = (state, draftId) => {
  const found = selectDraftById(state, draftId)
  return found ? found.attachmentsNormalized : emptyArr
}

export const selectMailboxIdForDraftByTicketId = createCachedSelector(
  selectMailboxIdFromUrl,
  selectCurrentMailboxId,
  selectPreferredMailboxId,
  selectFirstMailboxId,
  (mailboxIdFromUrl, currentMailboxId, preferredMailboxId, firstMailboxId) =>
    mailboxIdFromUrl || currentMailboxId || preferredMailboxId || firstMailboxId
)((_state, ticketId) => ticketId || 'unknown')

export const selectNewNoteDraftByTicketId = createCachedSelector(
  (state, ticketId) =>
    selectCurrentConversationById(state, ticketId, null, true),
  selectCurrentUserPreferReassignTicketOnNote,
  selectCurrentUserId,
  selectMailboxIdForDraftByTicketId,
  (ticket, reassignTicketOnNote, currentUserId, currentOrFirstMailboxId) => {
    const draftDefaults = {
      isNote: true,
      assigneeId: ticket?.assigned?.agent?.id,
      assigneeGroupId: ticket?.assigned?.team?.id,
      mailboxId: currentOrFirstMailboxId,
    }
    if (reassignTicketOnNote) {
      draftDefaults.assigneeId = currentUserId
    }
    draftDefaults.state = ticket?.state || OPENED
    return draftDefaults
  }
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectNewConversationDraftByTicketId = createSelector(
  selectIsLoggingNewConversation,
  selectMailboxIdForDraftByTicketId,
  selectCurrentUserId,
  selectCurrentUserPreferReassignTicketOnReply,
  (
    isLoggingNewConversation,
    currentOrFirstMailboxId,
    currentUserId,
    reassignTicketOnReply
  ) => {
    const isNote = isLoggingNewConversation
    const params = queryParams()
    const title = params.subject || ''
    const body = params.body || ''
    const to = []
    const cc = []
    const bcc = []

    if (params.to) {
      const parsed = emailParser.parseAddressList({
        input: params.to,
        rejectTLD: true,
      })
      if (parsed && parsed[0]) {
        const item = parsed[0]
        to.push({ email: item.address, name: item.name })
      }
    }
    if (params.cc) {
      const parsed = emailParser.parseAddressList({
        input: params.cc,
        rejectTLD: true,
      })
      parsed.forEach(item => {
        cc.push({ email: item.address, name: item.name })
      })
    }
    if (params.bcc) {
      const parsed = emailParser.parseAddressList({
        input: params.bcc,
        rejectTLD: true,
      })
      parsed.forEach(item => {
        bcc.push({ email: item.address, name: item.name })
      })
    }

    return {
      replyType: 'reply-all',
      mailboxId: currentOrFirstMailboxId,
      assigneeId: reassignTicketOnReply ? currentUserId : null,
      assigneeGroupId: null,
      to,
      cc,
      bcc,
      isNote,
      body,
      title,
    }
  }
)

export const selectReplyDraftByTicketId = createCachedSelector(
  (state, ticketId) =>
    selectCurrentConversationById(state, ticketId, null, true),
  (state, ticketId) =>
    selectConversationEventGroupsByConversationId(state, ticketId, true),
  selectCurrentUserId,
  selectCurrentUserPreferReassignTicketOnReply,
  selectAccountPrefersReplyOpen,

  (
    ticket,
    eventGroups,
    currentUserId,
    reassignTicketOnReply,
    prefersReplyOpen
  ) => {
    // Either the ticket doesnt exist or its loading. Either way we cant setup a reply
    // draft with no ticket
    if (!ticket || !eventGroups || eventGroups.length === 0) return null
    const draftDefaults = {
      replyType: 'reply-all',
      mailboxId: ticket.channel?.id,
      assigneeId: ticket.assigned?.agent?.id,
      assigneeGroupId: ticket.assigned?.team?.id,
      title: ticket.subject,
      state: CLOSED,
    }
    if (reassignTicketOnReply) {
      draftDefaults.assigneeId = currentUserId
    }
    if (prefersReplyOpen) {
      draftDefaults.state = OPENED
    }

    const firstReply = eventGroups.find(eg => eg.isFirstMessage)
    const lastReply = eventGroups.find(eg => eg.isLastMessage)
    if (lastReply && firstReply !== lastReply) {
      // If we've merged 2 converations, we also want to go ahead and merge the cc and bcc
      // fromt the 2 conversations so that the new reply includes everyone
      if (lastReply.fromMerge) {
        draftDefaults.cc = getUniqueRecipients(
          draftDefaults.cc,
          lastReply.summary?.cc
        )
        draftDefaults.bcc = getUniqueRecipients(
          draftDefaults.bcc,
          lastReply.summary?.bcc
        )
      } else {
        draftDefaults.cc = lastReply.summary?.cc
        draftDefaults.bcc = lastReply.summary?.bcc
      }

      const firstEndUserActor = firstEnduserActorFromDenormalizedEventGroup(
        firstReply
      )
      const lastEndUserActor = firstEnduserActorFromDenormalizedEventGroup(
        lastReply
      )

      // In our system we have the ability to change the enduser linked to the ticket
      // If the first and the last message have gone to the same actor, always use the contact
      // from the ticket to make sure that if customers change the contact on the ticket, the newq
      // contact is used
      if (firstEndUserActor?.id === lastEndUserActor?.id) {
        draftDefaults.to = [ticket.contact]
      } else {
        draftDefaults.to =
          // eslint-disable-next-line no-underscore-dangle
          lastReply.actor.__typename === 'Agent'
            ? lastReply.summary?.to
            : [lastReply.actor]
      }
    } else {
      draftDefaults.to = [ticket.contact]
    }
    return draftDefaults
  }
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectTwitterReplyDraftByTicketId = createCachedSelector(
  selectReplyDraftByTicketId,
  (state, ticketId) =>
    selectCurrentConversationById(state, ticketId, null, true),
  (state, ticketId) =>
    selectConversationEventGroupsByConversationId(state, ticketId, true),
  (replyDraft, ticket, eventGroups) => {
    const twitterDraft = { ...replyDraft }
    const contact = ticket.contact
    twitterDraft.isTwitter = true

    const twitterRecipients = []
    const twitterHandles = []
    const to = twitterDraft.to && twitterDraft.to[0]

    if (twitterHandleFromContact(to)) {
      twitterRecipients.push(to)
      twitterHandles.push(`@${twitterHandleFromContact(to)}`)
    } else if (twitterHandleFromContact(contact)) {
      twitterRecipients.push({
        twitter: { username: twitterHandleFromContact(contact) },
      })
      twitterHandles.push(`@${twitterHandleFromContact(contact)}`)
    }
    delete twitterDraft.to
    delete twitterDraft.cc
    delete twitterDraft.bcc

    const firstReply = eventGroups.find(eg => eg.isFirstMessage)
    const lastReply = eventGroups.find(eg => eg.isLastMessage)
    const customerMessage = lastReply || firstReply

    if (customerMessage) {
      const {
        summary: { bodyPlainText },
      } = customerMessage

      const actorHandle = twitterHandleFromContact(customerMessage.actor)
      const accountHandle = getAccountTwitterUsername(ticket)
      const otherHandles = extractTwitterHandles(bodyPlainText).filter(
        item => item !== accountHandle
      )

      const handles = [actorHandle, ...otherHandles].filter(h => !!h)
      handles.forEach(handle => {
        if (handle) {
          const username = handle.substr(1)
          if (
            twitterRecipients.length === 0 ||
            (twitterRecipients.length > 0 &&
              twitterRecipients[0].customFieldValues?.contact_twitter?.value
                ?.text &&
              twitterRecipients[0].customFieldValues?.contact_twitter?.value
                ?.text !== username)
          ) {
            twitterHandles.push(`@${username}`)
            twitterRecipients.push({
              customFieldValues: {
                contact_twitter: { value: { text: username } },
              },
            })
          }
        }
      })
    }

    twitterDraft.twitterPrefix =
      twitterHandles.length > 0 && `${twitterHandles.join(' ')} `
    twitterDraft.twitterRecipients = twitterRecipients

    return twitterDraft
  }
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectFacebookReplyDraftByTicketId = createCachedSelector(
  selectReplyDraftByTicketId,
  replyDraft => {
    const facebookDraft = { ...replyDraft }
    facebookDraft.isFacebook = true

    const to = facebookDraft.to && facebookDraft.to[0]

    delete facebookDraft.cc
    delete facebookDraft.bcc
    facebookDraft.facebookRecipient = to

    return facebookDraft
  }
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftDefaultByTicketId = createBasicSelector(
  (state, ticketId, draftType) => {
    const ticket = selectCurrentConversationById(state, ticketId)
    if (ticketId === 'new') {
      if (draftType === 'note') {
        return selectNewNoteDraftByTicketId(state, ticketId, draftType)
      }
      return selectNewConversationDraftByTicketId(state, ticketId, draftType)
    } else if (isTwitter(ticket)) {
      return selectTwitterReplyDraftByTicketId(state, ticketId, draftType)
    } else if (isFacebook(ticket)) {
      return selectFacebookReplyDraftByTicketId(state, ticketId, draftType)
    }
    return selectReplyDraftByTicketId(state, ticketId, draftType)
  }
)

export const selectDraftByTicketId = createCachedSelector(
  selectById,
  selectDraftIdByTicketId,
  selectDraftDefaultByTicketId,
  (byId, draftId, draftDefaults) => {
    const draft = byId[draftId]
    return draft || draftDefaults
  }
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftBodyByTicketId = (state, ticketId, type) => {
  const found = selectDraftByTicketId(state, ticketId, type)
  return found ? found.body : null
}

export const selectCurrentTicketReplyDraft = createBasicSelector(
  selectCurrentTicket,
  state => state,
  (ticket, state) => selectDraftByTicketId(state, ticket?.id, 'reply')
)

export const selectCurrentTicketNoteDraft = createBasicSelector(
  selectCurrentTicket,
  state => state,
  (ticket, state) => selectDraftByTicketId(state, ticket?.id, 'note')
)

export const selectCurrentTicketDraftByInferredType = createSelector(
  selectIsAddingNote,
  selectCurrentTicketNoteDraft,
  selectCurrentTicketReplyDraft,
  (isAddingNote, noteDraft, replyDraft) =>
    isAddingNote ? noteDraft : replyDraft
)

export const selectCurrentRecipientSyncSource = state =>
  state[storeKey].currentRecipientSync?.source

export const selectIsSyncingRecipients = createBasicSelector(
  selectCurrentRecipientSyncSource,
  source => !!source
)

export const selectCurrentTicketReplyDraftHasBody = createSelector(
  selectCurrentTicketReplyDraft,
  draft => !!(draft && draft.body && stripHTML(draft.body))
)

export const selectCurrentTicketReplyDraftTo = createSelector(
  selectCurrentTicketReplyDraft,
  draft => draft?.to
)

export const selectCurrentTicketReplyDraftAllRecipientIds = createSelector(
  selectCurrentTicketReplyDraft,
  draft => {
    if (!draft) return []
    const all = {}
    ;['to', 'cc', 'bcc'].forEach(type => {
      const recipients = draft[type] || []
      recipients.forEach(r => {
        if (r.id) {
          all[r.id] = r.id
        }
      })
    })

    return Object.values(all)
  }
)

export const selectDraftAssigneeIdByTicketId = createCachedSelector(
  selectDraftByTicketId,
  draft => draft?.assigneeId
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftAssigneeByTicketId = createCachedSelector(
  selectDraftAssigneeIdByTicketId,
  selectCurrentAgentsById,
  (assigneeId, agentsById) => agentsById[assigneeId]
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftAssigneeGroupIdByTicketId = createCachedSelector(
  selectDraftByTicketId,
  draft => draft?.assigneeGroupId
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftAssigneeGroupByTicketId = createCachedSelector(
  selectDraftAssigneeGroupIdByTicketId,
  selectCurrentTeamsById,
  (assigneeGroupId, teamsById) => teamsById[assigneeGroupId]
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectDraftAssignmentLabelByTicketId = createCachedSelector(
  selectDraftAssigneeByTicketId,
  selectDraftAssigneeGroupByTicketId,
  selectCurrentUserId,
  assignmentLabel
)((_state, ticketId, type) => `${ticketId || 'unknown'}-${type || 'unknown'}`)

export const selectCurrentDraftContactId = createBasicSelector(
  selectCurrentTicketDraftByInferredType,
  draft => {
    return draft?.to?.[0]?.contact_id
  }
)

export const selectCurrentDraftContactIdOrTemp = createSelector(
  selectCurrentTicket,
  selectCurrentDraftContactId,
  (ticket, contactId) => {
    return contactId || (ticket && `forConversation:${ticket.id}`)
  }
)
