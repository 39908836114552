import moment from 'moment'
import createCachedSelector from 're-reselect'

import { createSelector } from 'reselect'

import { selectContactDetailsColumnPreferencesIsLoaded } from 'ducks/agentPreferences'

import { selectCurrentContactMatrixUserPresence } from 'ducks/chat/selectors/presence'
import { selectSubjectById } from 'ducks/crm/customFieldCategories/selectors/subject/selectSubjectById'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'
import { selectCurrentTicketRawMailboxId } from 'ducks/mailboxes/selectors/selectCurrentTicketRawMailboxId'
import { emptyArr } from 'util/arrays'
import { selectChannelCustomFieldsGroupsByKey } from 'ducks/crm/channels/selectors/selectChannelCustomFieldsGroupsByKey'
import { selectCurrentContact } from './current'
import { selectSubjectForCategoryType } from '../../customFieldCategories/selectors'
import { selectAllCustomFieldsIsLoaded } from '../../customFields/selectors'

export const selectCustomFieldValueForCustomFieldKeyAndCategoryType = createSelector(
  selectSubjectForCategoryType,
  (state, { customFieldKey }) => customFieldKey,
  (subject, customFieldKey) => {
    if (!subject) return null
    return (
      subject.customFieldValues && subject.customFieldValues[customFieldKey]
    )
  }
)

export const selectCustomFieldValueContentForCustomFieldKeyAndCategoryType = createSelector(
  selectSubjectForCategoryType,
  (state, { customFieldKey }) => customFieldKey,
  (subject, customFieldKey) => {
    if (!subject) return null
    const customFieldValues = subject.customFieldValues
    if (!customFieldValues) return null

    const value = customFieldValues[customFieldKey]
    if (!value) return null

    return value.value?.content || value.value
  }
)

export const selectCustomFieldValuesContentForCustomFieldKeysAndCategoryType = createCachedSelector(
  selectSubjectForCategoryType,
  (state, { categoryType }) => categoryType,
  (state, _, fieldKeys) => fieldKeys,
  (subject, categoryType, fieldKeys) => {
    const fields = {}

    if (!subject) return fields
    const customFieldValues = subject.customFieldValues
    if (!customFieldValues) return fields

    fieldKeys.forEach(field => {
      const value = customFieldValues[field[1]]
      fields[field[0]] = value?.value?.content
    })

    return fields
  }
)((state, { categoryType }) => categoryType)

export const selectCurrentContactName = createSelector(
  selectCurrentContact,
  contact => {
    const {
      contact_first_name: { value: { content: firstName } = {} } = {},
      contact_last_name: { value: { content: lastName } = {} } = {},
      contact_email: { value: { content: email } = {} } = {},
    } =
      contact?.customFieldValues || {}
    return (
      `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName ||
        ''}` ||
      email ||
      'Unknown contact'
    )
  }
)

const getCustomFieldValueWithSubKey = (customFieldValue, subKey) => {
  if (Array.isArray(customFieldValue?.value)) {
    return customFieldValue.value.map(value => {
      if (subKey) {
        return value && value[subKey]
      }
      return value
    })
  } else if (subKey) {
    return customFieldValue?.value && customFieldValue.value[subKey]
  }
  return customFieldValue?.value
}

export const selectAvatarUrl = createCachedSelector(
  (_, { categoryType }) => categoryType,
  (state, { categoryType }) => {
    const val = selectCustomFieldValueForCustomFieldKeyAndCategoryType(state, {
      customFieldKey: 'contact_avatar',
      categoryType,
    })
    return (
      getCustomFieldValueWithSubKey(val, 'url') ||
      getCustomFieldValueWithSubKey(val, 'dataUrl')
    )
  },
  (state, { categoryType }) => {
    const val = selectCustomFieldValueForCustomFieldKeyAndCategoryType(state, {
      customFieldKey: 'contact_avatar_url',
      categoryType,
    })
    return val?.value
  },
  (state, { categoryType }) =>
    selectSubjectForCategoryType(state, { categoryType }),
  (_, customFieldUrlOrDataUrl, customFieldContactAvatarUrl, subject) => {
    return (
      customFieldUrlOrDataUrl ||
      (customFieldContactAvatarUrl instanceof Object
        ? customFieldContactAvatarUrl?.content
        : customFieldContactAvatarUrl) ||
      subject?.avatarUrl
    )
  }
)((_state, { categoryType }) => categoryType)

function getCustomFieldFromSubject(subject, customFieldKey) {
  if (!subject) return null
  return subject.customFieldValues && subject.customFieldValues[customFieldKey]
}
export const selectCustomFieldValueForCustomFieldKeyAndSubjectId = createSelector(
  (state, { subjectId }) => selectSubjectById(state, subjectId),
  (state, { customFieldKey }) => customFieldKey,
  getCustomFieldFromSubject
)

export const selectCurrentContactCustomFieldValueForCustomFieldKey = createSelector(
  selectCurrentContact,
  (state, { customFieldKey }) => customFieldKey,
  getCustomFieldFromSubject
)

function formatDate(date) {
  if (!date) return ''
  const asDate = new Date(date)
  const now = new Date()
  let format = 'H:mmA'
  if (
    asDate.getDate() !== now.getDate() &&
    asDate.getDate() !== now.getDate()
  ) {
    if (asDate.getFullYear() === now.getFullYear()) format = `D MMM, ${format}`
    else format = `D MMM 'YY, ${format}`
  }
  return moment(date).format(format)
}

export const selectCurrentContactLastSeenAt = createSelector(
  selectCurrentContact,
  contact => {
    const {
      contact_latest_session: {
        value: { lastPageView: { occurredAt } = {} } = {},
      } = {},
    } =
      contact.customFieldValues || {}
    return formatDate(occurredAt)
  }
)

export const selectCurrentContactLastSeenIpLocation = createSelector(
  selectCurrentContact,
  contact => {
    const {
      contact_ip_address: { value: { city, subdivisions, country } = {} } = {},
    } =
      contact.customFieldValues || {}
    return [city, subdivisions, country].filter(x => !!x).join(', ')
  }
)

export const selectCanPageOnClient = createSelector(
  selectContactDetailsColumnPreferencesIsLoaded,
  selectAllCustomFieldsIsLoaded,
  (agentPreferencesLoaded, customFieldsLoaded) => {
    return customFieldsLoaded && agentPreferencesLoaded
  }
)
export const selectLocalTime = createSelector(selectCurrentContact, contact => {
  const {
    contact_browser_time_zone: {
      value: { content: browserTimeZone } = {},
    } = {},
    contact_timezone: { value: { content: contactTimeZone } = {} } = {},
    company_timezone: { value: { content: companyTimeZone } = {} } = {},
  } =
    contact?.customFieldValues || {}
  const timezone = browserTimeZone || contactTimeZone || companyTimeZone
  const localTime =
    timezone &&
    moment()
      .tz(timezone)
      .format('HH:mm')
  if (!localTime) return null
  return `${localTime} - ${timezone || ''}`
})

export const selectLastSeenAt = createSelector(
  selectCurrentContact,
  selectCurrentContactMatrixUserPresence,
  (contact, status) => {
    const { lastSeenAt } = contact || {}
    const formattedLastSeenAt =
      lastSeenAt && moment(lastSeenAt).format("D MMM 'YY, H:mmA")
    return status === 'online' ? 'Online now' : formattedLastSeenAt || null
  }
)

export const selectCurrentConversationCustomFieldGroupKey = createSelector(
  selectCurrentTicketRawMailboxId,
  rawMailboxId => `cfm_${rawMailboxId}`
)

export const selectCurrentConversationCustomFieldGroup = createSelector(
  selectCurrentConversationCustomFieldGroupKey,
  selectChannelCustomFieldsGroupsByKey,
  (groupKey, groupsByKey) => {
    return groupsByKey[groupKey] || emptyArr
  }
)

export const selectCurrentConversationRequiredCustomFields = createSelector(
  selectCurrentConversationCustomFieldGroup,
  customFields => {
    return customFields.filter(cf => cf.required)
  }
)

export const selectIsRequiredConversationFieldsFilled = createSelector(
  selectCurrentConversation,
  selectCurrentConversationRequiredCustomFields,
  (conversation, customFields) => {
    if (!conversation || !Array.isArray(customFields)) return true
    const customFieldValues = conversation?.customFieldValues || {}

    return !customFields.some(cf => {
      return !customFieldValues[cf.key]
    })
  }
)
