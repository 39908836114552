import { createSelector } from 'reselect'

import {
  selectCurrentConversationOrConversationDraftContactId,
  selectCurrentConversationOrConversationDraftContactIdOrTemp,
} from 'selectors/tickets/customer'
import { selectCurrentCustomerEmail } from 'selectors/tickets/customer/selectCurrentCustomerEmail'
import { selectCurrentMatrixUserId } from 'ducks/chat/selectors/users'

import { memoize } from 'util/memoization'
import createCachedSelector from 're-reselect'
import { CONTACT_PAGE } from '../../pages'
import {
  selectContactIdsByIdentifyingValue,
  selectContactsById,
  selectContactStatesById,
} from './base'

export function selectContactPageContactId(state) {
  if (state.location.type === CONTACT_PAGE) {
    return state.location.payload.id || null
  }
  return null
}

export const selectCurrentChatContactId = createSelector(
  selectCurrentMatrixUserId,
  selectContactIdsByIdentifyingValue,
  (matrixId, { contact_matrix_id: contactIdsByIdentifyingValue = {} }) => {
    return matrixId && contactIdsByIdentifyingValue[matrixId]
  }
)

export const selectContactIdByMatrixUserId = createCachedSelector(
  selectContactIdsByIdentifyingValue,
  (_state, matrixUserId) => matrixUserId,
  ({ contact_matrix_id: contactIdsByIdentifyingValue = {} }, matrixUserId) =>
    contactIdsByIdentifyingValue[matrixUserId]
)((_state, matrixUserId) => matrixUserId || 'unknown')

export const selectCurrentChatContactIdOrTemp = createSelector(
  selectCurrentChatContactId,
  selectCurrentMatrixUserId,
  (contactId, matrixId) => contactId || `forMatrixId:${matrixId}`
)

export const selectCurrentContactId = createSelector(
  selectContactPageContactId,
  selectCurrentConversationOrConversationDraftContactId,
  selectCurrentChatContactId,
  (contactPageContactId, conversationContactId, currentChatContactId) => {
    return contactPageContactId || conversationContactId || currentChatContactId
  }
)

export const selectCurrentContactIdOrTemp = createSelector(
  selectContactPageContactId,
  selectCurrentConversationOrConversationDraftContactIdOrTemp,
  selectCurrentChatContactIdOrTemp,
  (contactPageContactId, conversationContactId, currentChatContactId) => {
    return contactPageContactId || conversationContactId || currentChatContactId
  }
)

const justContactId = memoize(id => ({ id }))

export const selectCurrentContact = createSelector(
  selectCurrentContactIdOrTemp,
  selectContactsById,
  (id, byId) => {
    return id ? byId[id] : justContactId(id)
  }
)

export const selectCurrentContactStates = createSelector(
  selectCurrentContactIdOrTemp,
  selectContactStatesById,
  (id, byId) => {
    return id ? byId[id] : justContactId(id)
  }
)

export const selectCurrentContactIsMissing = createSelector(
  selectCurrentContactStates,
  contact => contact && contact.isMissing === true
)

export const selectCurrentContactIsCreating = createSelector(
  selectCurrentContactStates,
  contact => contact && contact.isCreating === true
)

export const selectCurrentContactHasSomeCustomFieldValues = createSelector(
  selectCurrentContact,
  contact => {
    return !!(contact && contact.customFieldValues)
  }
)

export const selectCurrentContactEmail = createSelector(
  selectCurrentContact,
  contact => contact?.email
)

export const selectCurrentContactOrCustomerEmail = createSelector(
  selectCurrentCustomerEmail,
  selectCurrentContactEmail,
  (customerEmail, contactEmail) => {
    return contactEmail || customerEmail
  }
)
