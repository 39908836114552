import { selectPrefersAgentsCanMoveToInaccessibleMailboxes } from 'ducks/currentUser/selectors/preferences/selectPrefersAgentsCanMoveToInaccessibleMailboxes'
import { createSelector } from 'reselect'
import { sortByKeyInsensitive, emptyArr } from 'util/arrays'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import { selectCurrentTicketMailboxId } from './selectCurrentTicketMailboxId'
import { selectSelectedTicketsMailboxes } from './selectSelectedTicketsMailboxes'
import { selectInaccessibleMailboxes } from './selectInaccessibleMailboxes'
import { selectKnownMailboxes } from './selectKnownMailboxes'

export const selectPossibleMailboxesForChange = createSelector(
  selectCurrentTicketMailboxId,
  selectKnownMailboxes,
  selectSelectedTicketsMailboxes,
  selectInaccessibleMailboxes,
  selectPrefersAgentsCanMoveToInaccessibleMailboxes,
  selectIsTicketSelectionMode,
  (
    currentMailboxId,
    knownMailboxes,
    selectedTickets,
    inaccessibleMailboxes,
    canMoveToInaccessible,
    isBulkMode
  ) => {
    let possibleMailboxes
    if (isBulkMode) {
      possibleMailboxes = knownMailboxes
    } else {
      let mailboxId = currentMailboxId
      if (!mailboxId && selectedTickets && selectedTickets.length === 1) {
        mailboxId = selectedTickets[0].id
      }
      possibleMailboxes = knownMailboxes.filter(
        mailbox => mailbox.id !== mailboxId
      ) // exclude draft mailbox
    }
    const joined =
      canMoveToInaccessible && inaccessibleMailboxes
        ? possibleMailboxes.concat(inaccessibleMailboxes)
        : possibleMailboxes
    const mailboxes = sortByKeyInsensitive(joined, 'label')
    return mailboxes.length > 0 ? mailboxes : emptyArr
  }
)
